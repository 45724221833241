import React, { useState } from "react";
import seshagiri from "../../assets/images/seshagiri.png";
import StephanSchuster from "../../assets/images/StephanSchuster.png";
import sathish from "../../assets/images/sathish.png";
import KKN from "../../assets/images/KKN.png";
import ramchanth from "../../assets/images/ramchanth.png";
import arjula from "../../assets/images/arjula.png";
import pradeep_palazhi from "../../assets/images/pradeep_palazhi.png";
import dan_peterson from "../../assets/images/dan_peterson.png";
import padmanabha_shenoy from "../../assets/images/padmanabha_shenoy.png";
import manoj_krishnan from "../../assets/images/manoj_krishnan.png";
import sachdev_sidhu from "../../assets/images/sachdev_sidhu.png";
import shane_miersch from "../../assets/images/shane_miersch.png";
import AboutSheshagiri from "../scientific-advisers/AboutSheshagiri";
import AboutStephanSchuster from "../scientific-advisers/AboutStephanSchuster";
import AboutSathish from "../scientific-advisers/AboutSathish";
import AboutArjulaReddy from "../scientific-advisers/AboutArjulaReddy";
import AboutKKN from "../scientific-advisers/AboutKKN";
import AboutCNR from "../scientific-advisers/AboutCNR";
import AboutManojKrishnan from "../scientific-advisers/AboutManojKrishnan";
import AboutShaneMiersch from "../scientific-advisers/AboutShaneMiersch";

import { motion } from "framer-motion";

const ManagementTeam = () => {
  const [activeTopBar, setActiveTopBar] = useState("");
  return (
    <>
      <motion.div
        initial={{ y: "10vw", opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.4 }}
        className="management"
      >
        <h1 className=" text-white text-xl sm:text-2xl md:text-3xl lg:text-4xl mb-5 text-center">
          Scientific Advisers
        </h1>
        <div className="managementWraper flex flex-wrap p-1 justify-center">
          <div className="managementTeamBox w-full md:w-6/12 p-1 flex justify-center">
            <div className="managementTeamContent p-1 w-full flex flex-col items-center">
              <img
                onClick={(e) => setActiveTopBar(e.target.dataset.name)}
                data-name="pradeep_palazhi"
                className=" mb-4 cursor-pointer"
                src={pradeep_palazhi}
                alt=""
              />
              <h1 className="text-2xl">Pradeep Palazhi</h1>
              <h3 className="text-sm">CEO, Value Add Solutions</h3>
            </div>
          </div>
          <div className="managementTeamBox w-full md:w-6/12 p-1 flex justify-center">
            <div className="managementTeamContent p-1 w-full flex flex-col items-center">
              <img
                onClick={(e) => setActiveTopBar(e.target.dataset.name)}
                data-name="dan_peterson"
                className=" mb-4 cursor-pointer"
                src={dan_peterson}
                alt=""
              />
              <h1 className="text-2xl">Dan Peterson</h1>
              <h3 className="text-sm">Founder, Loyale Healthcare</h3>
            </div>
          </div>
          <div className="managementTeamBox w-full md:w-6/12 p-1 flex justify-center mt-5">
            <div className="managementTeamContent p-1 w-full flex flex-col items-center">
              <img
                onClick={(e) => setActiveTopBar(e.target.dataset.name)}
                data-name="padmanabha_shenoy"
                className=" mb-4 cursor-pointer"
                src={padmanabha_shenoy}
                alt=""
              />
              <h1 className="text-2xl">Dr. Padmanabha Shenoy</h1>
              <h3 className="text-sm">Clinical Immunologist. CEO, CARE</h3>
            </div>
          </div>
          <div className="managementTeamBox w-full md:w-6/12 p-1 flex justify-center mt-5">
            <div className="managementTeamContent p-1 w-full flex flex-col items-center">
              <img
                onClick={(e) => setActiveTopBar(e.target.dataset.name)}
                data-name="manoj_krishnan"
                className=" mb-4 cursor-pointer"
                src={manoj_krishnan}
                alt=""
              />
              <h1 className="text-2xl">Dr. Manoj Krishnan</h1>
              <h3 className="text-sm">Pharma Lead – Manus Bio</h3>
            </div>
          </div>
          <div className="managementTeamBox w-full md:w-6/12 p-1 flex justify-center mt-5">
            <div className="managementTeamContent p-1 w-full flex flex-col items-center">
              <img
                onClick={(e) => setActiveTopBar(e.target.dataset.name)}
                data-name="cnr"
                className=" mb-4 cursor-pointer"
                src={ramchanth}
                alt=""
              />
              <h1 className="text-2xl">Dr. CN Ramchand</h1>
              <h3 className="text-sm">
                President and CEO of SAKSIN Life Sciences
              </h3>
            </div>
          </div>
          <div className="managementTeamBox w-full md:w-6/12 p-1 flex justify-center mt-5">
            <div className="managementTeamContent p-1 w-full flex flex-col items-center">
              <img
                onClick={(e) => setActiveTopBar(e.target.dataset.name)}
                data-name="sachdev_sidhu"
                className=" mb-4 cursor-pointer"
                src={sachdev_sidhu}
                alt=""
              />
              <h1 className="text-2xl">Dr. Sachdev Sidhu</h1>
              <h3 className="text-sm">University of Waterloo</h3>
            </div>
          </div>
          <div className="managementTeamBox w-full md:w-6/12 p-1 flex justify-center mt-5">
            <div className="managementTeamContent p-1 w-full flex flex-col items-center">
              <img
                onClick={(e) => setActiveTopBar(e.target.dataset.name)}
                data-name="shane_miersch"
                className=" mb-4 cursor-pointer"
                src={shane_miersch}
                alt=""
              />
              <h1 className="text-2xl">Dr. Shane Miersch</h1>
              <h3 className="text-sm">University of Waterloo</h3>
            </div>
          </div>
        </div>
      </motion.div>
{/*
      <div
        onClick={(e) =>
          Array.from(e.target.classList).includes("active")
            ? setActiveTopBar("")
            : null
        }
        className={` overlay ${activeTopBar === "pradeep_palazhi" ? "active" : ""}`}
      >
        <AboutPradeepPalazhi
          activeTopBar={activeTopBar}
          setActiveTopBar={setActiveTopBar}
        />
      </div>
*/}
{/*
      <div
        onClick={(e) =>
          Array.from(e.target.classList).includes("active")
            ? setActiveTopBar("")
            : null
        }
        className={` overlay ${activeTopBar === "dan_peterson" ? "active" : ""}`}
      >
        <AboutDanPeterson
          activeTopBar={activeTopBar}
          setActiveTopBar={setActiveTopBar}
        />
      </div>
*/}
{/*
      <div
        onClick={(e) =>
          Array.from(e.target.classList).includes("active")
            ? setActiveTopBar("")
            : null
        }
        className={` overlay ${activeTopBar === "padmanabha_shenoy" ? "active" : ""}`}
      >
        <AboutPadmanabhaShenoy
          activeTopBar={activeTopBar}
          setActiveTopBar={setActiveTopBar}
        />
      </div>
*/}
      <div
        onClick={(e) =>
          Array.from(e.target.classList).includes("active")
            ? setActiveTopBar("")
            : null
        }
        className={` overlay ${activeTopBar === "manoj_krishnan" ? "active" : ""}`}
      >
        <AboutManojKrishnan
          activeTopBar={activeTopBar}
          setActiveTopBar={setActiveTopBar}
        />
      </div>
      <div
        onClick={(e) =>
          Array.from(e.target.classList).includes("active")
            ? setActiveTopBar("")
            : null
        }
        className={` overlay ${activeTopBar === "shane_miersch" ? "active" : ""}`}
      >
        <AboutShaneMiersch
          activeTopBar={activeTopBar}
          setActiveTopBar={setActiveTopBar}
        />
      </div>
      <div
        onClick={(e) =>
          Array.from(e.target.classList).includes("active")
            ? setActiveTopBar("")
            : null
        }
        className={` overlay ${activeTopBar === "cnr" ? "active" : ""}`}
      >
        <AboutCNR
          activeTopBar={activeTopBar}
          setActiveTopBar={setActiveTopBar}
        />
      </div>
    </>
  );
};

export default ManagementTeam;
