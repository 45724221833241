import React from "react";
import { motion } from "framer-motion";
import {
  KeyboardArrowUpOutlined,
  RadioButtonUncheckedOutlined,
} from "@mui/icons-material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

const CareerDetails = ({
  id,
  currentActive,
  setCurrentActive,
  title,
  responsibility,
  background,
  required_skills,
  who_you_are,
  location,
}) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: id * 0.2, type: "tween" }}
      className={`border border-[#00b3ff93] careerDetails overflow-hidden mt-2 ${
        currentActive === id ? "bg-[#092844]" : "bg-[#053A6B]"
      } rounded-md`}
    >
      <div className="careerDetailTitle p-4 rounded-md flex justify-between">
        <h2 className="text-white text-base md:text-lg lg:text-2xl">{title}</h2>
        <button
          className=" border border-[#00b3ff93] text-white px-4 text-normal py-0 rounded-full"
          data-id={id}
          onClick={(e) =>
            setCurrentActive((prev) => {
              if (prev === id) {
                return null;
              } else {
                return +e.target.dataset.id;
              }
            })
          }
        >
          Details
          <KeyboardArrowUpOutlined
            className={`UpArrow ${currentActive === id ? "rotate" : ""}`}
          />
        </button>
      </div>
      {currentActive === id && (
        <motion.div
          initial={{ x: "-10vw", opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ delay: 0.3 }}
          className="careerDetailContent p-4 text-white"
        >
          {background && (
            <>
              <h3 className="text-base md:text-lg lg:text-xl mb-4">
                Background
              </h3>
              <p className=" mb-4 text-normal text-slate-300">{background}</p>
            </>
          )}

          {responsibility.length > 0 && (
            <>
              <h3 className=" text-base md:text-lg lg:text-xl">
                Job Responsiblities
              </h3>
              <ul className=" text-slate-300 mt-2">
                {responsibility.map((res, i) => (
                  <li key={i} className="responsiblities mb-1 flex gap-2">
                    <div className="">
                      <ArrowRightIcon fontSize="large" />{" "}
                    </div>
                    <div className=" pt-2">
                      <p className=" text-normal">{res}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </>
          )}

          {required_skills.length > 0 && (
            <>
              <h3 className=" text-base md:text-lg lg:text-xl">
                Required Skills
              </h3>
              <ul className=" text-slate-300 mt-2">
                {required_skills.map((skill, i) => (
                  <li key={i} className="responsiblities mb-1 flex gap-2">
                    <div className="">
                      <ArrowRightIcon fontSize="large" />{" "}
                    </div>
                    <div className=" pt-2">
                      <p className=" text-normal">{skill}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </>
          )}

          {who_you_are.length > 0 && (
            <>
              <h3 className=" text-base md:text-lg lg:text-xl">Who you are</h3>
              <ul className=" text-slate-300 mt-2">
                {who_you_are.map((whoyou, i) => (
                  <li key={i} className="responsiblities mb-1 flex gap-2">
                    <div className="">
                      <ArrowRightIcon fontSize="large" />{" "}
                    </div>
                    <div className=" pt-2">
                      <p className=" text-normal">{whoyou}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </>
          )}

          {Object.keys(location).length > 0 && (
            <>
              <h3 className=" text-base md:text-lg lg:text-xl">Location</h3>
              <div className="location flex text-normal gap-3 mb-4">
                <span className="city">{location.city},</span>
                <span className="state">{location.state},</span>
                <span className="country">{location.country}</span>
              </div>
            </>
          )}

          <p>
            Interested candidates can sent their resume to{" "}
            <a className=" text-teal-200" href="mailto:hr@scigenom.com">
              hr@scigenom.com
            </a>{" "}
          </p>
        </motion.div>
      )}
    </motion.div>
  );
};

export default CareerDetails;
