import React, { useState, useEffect } from "react";
import "./About.css";
import ListIcon from "@mui/icons-material/List";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CompanyOverview from "../../components/about-components/CompanyOverview";
import CompanyVision from "../../components/about-components/CompanyVision";
import GrouthHistory from "../../components/about-components/GrouthHistory";
import ManagementTeam from "../../components/about-components/ManagementTeam";
import ScientificAdvisers from "../../components/about-components/ScientificAdvisers";
import Footer from "../../components/footer/Footer";

const About = ({ setShowIso }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeSection, setActiveSection] = useState("aboutus");
  const [activeTopBar, setActiveTopBar] = useState("");

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <div className="aboutWrapper pt-24 h-screen">
        <div className="flex px-4 md:px-7 lg:px-32 mt-6">
          <div className="left w-1/12 md:w-3/12 pl-2">
            <div className="aboutusLinks">
              <ul>
                <li>
                  <div
                    onClick={() => setActiveSection("aboutus")}
                    className={`aboutUsSecTitle ${
                      activeSection === "aboutus" ? "active" : ""
                    } text-sm hidden md:block`}
                  >
                    About Us
                  </div>
                </li>
                <li>
                  <div
                    onClick={() => setActiveSection("vision")}
                    className={`aboutUsSecTitle ${
                      activeSection === "vision" ? "active" : ""
                    } text-sm hidden md:block`}
                  >
                    Vision
                  </div>
                </li>
                <li>
                  <div
                    onClick={() => setActiveSection("grouth")}
                    className={`aboutUsSecTitle ${
                      activeSection === "grouth" ? "active" : ""
                    } text-sm hidden md:block`}
                  >
                    Growth History
                  </div>
                </li>
                <li>
                  <div
                    onClick={() => setActiveSection("management")}
                    className={`aboutUsSecTitle ${
                      activeSection === "management" ? "active" : ""
                    } text-sm hidden md:block`}
                  >
                    Management Team
                  </div>
                </li>
                <li>
                  <div
                    onClick={() => setActiveSection("advisers")}
                    className={`aboutUsSecTitle ${
                      activeSection === "advisers" ? "active" : ""
                    } text-sm hidden md:block`}
                  >
                    Scientific Advisors
                  </div>
                </li>
                <li>
                  <span
                    className=" text-white cursor-pointer md:hidden"
                    onClick={handleClick}
                  >
                    <ListIcon fontSize="large" />
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="right w-11/12 md:w-9/12  pl-10">
            <div className="aboutUsContent bg-transparent max-h-[80vh] md:max-h-[80vh] overflow-y-auto">
              {activeSection === "aboutus" && (
                <CompanyOverview
                  setActiveSection={setActiveSection}
                  setActiveTopBar={setActiveTopBar}
                />
              )}
              {activeSection === "vision" && <CompanyVision />}
              {activeSection === "grouth" && <GrouthHistory />}
              {activeSection === "management" && (
                <ManagementTeam
                  activeTopBar={activeTopBar}
                  setActiveTopBar={setActiveTopBar}
                />
              )}
              {activeSection === "advisers" && <ScientificAdvisers />}
            </div>
          </div>
        </div>
        <div className="mobileMenu">
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                setActiveSection("aboutus");
                handleClose();
              }}
            >
              About Us
            </MenuItem>
            <MenuItem
              onClick={() => {
                setActiveSection("vision");
                handleClose();
              }}
            >
              Vision
            </MenuItem>
            <MenuItem
              onClick={() => {
                setActiveSection("grouth");
                handleClose();
              }}
            >
              Grouth History
            </MenuItem>
            <MenuItem
              onClick={() => {
                setActiveSection("management");
                handleClose();
              }}
            >
              Management Team
            </MenuItem>
            <MenuItem
              onClick={() => {
                setActiveSection("advisers");
                handleClose();
              }}
            >
              Scientific Advisors
            </MenuItem>
          </Menu>
        </div>
      </div>
      <Footer setShowIso={setShowIso} />
    </>
  );
};

export default About;
