const axios = require("axios").default;
const url = `${process.env.REACT_APP_SITE_URL}/sendmail.php`;

const sentMail = async (formData) => {
  try {
    const response = await axios.post(url, formData);
    const { data } = response;
    return data;
  } catch (err) {
    console.log(err);
  }
};

export default sentMail;
