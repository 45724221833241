import React from "react";
import { motion } from "framer-motion";

const CompanyVision = () => {
  return (
    <motion.div
      initial={{ y: "-20vw", opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      className="companyOverview text-slate-300 text-xs sm:text-base md:text-base lg:text-lg"
    >
      <h1 className=" text-white text-xl sm:text-2xl md:text-3xl lg:text-4xl mb-5">
        Company Vision
      </h1>
      <div className="">
        <p className=" w-full text-base sm:text-base md:text-base">
          SciGenom was founded in 2010 by Sam Santhosh with the goal of enabling
          genomics in India. The evolution of Next Generation sequencing
          technologies was rapidly bringing the cost of sequencing down. The day
          of the $1000 genome looked near. This would open up revolutionary
          wealth creation opportunities across different verticals. Sam believed
          that SciGenom could be set up as service organization delivering
          sequencing and bioinformatics solutions mainly to the Indian market,
          while incubating industry specific solutions and products in house.
          When any of the vertical solution or product attained traction, it
          would be spun off as a separate company.
        </p>
      </div>

      <p className=" mt-5 text-base sm:text-base md:text-base">
        When the two new companies, MedGenome and AgriGenome, were formed, all
        of the sequencing and bioinformatics services were shifted to the new
        companies. SciGenom is an incubator where new activities are incubated
        and nurtured. Currently, development of antibodies for therapeutic and
        reagent purposes; and cloning and related services, are the main
        activities.
      </p>
    </motion.div>
  );
};

export default CompanyVision;
