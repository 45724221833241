import { Close, Menu } from "@mui/icons-material";
import React, { useState } from "react";
import scigenomeLogo from "../../assets/logos/scigenome_logo.png";
import { Link } from "react-router-dom";
import "./Navbar.css";

const Navbar = ({ scrollTop, activeNavMenu, setActiveNavMenu }) => {
  const [mobile, setMobile] = useState(false);

  return (
    <>
      <div
        className={`${
          scrollTop > 100 ? "Blur" : ""
        } flex justify-between items-center h-auto bg-transparent fixed z-10 top-0 w-full px-4 sm:px-10 md:px-7 lg:px-32  py-5`}
      >
        <div className="">
          <h1 className=" font-extrabold text-white">
            <Link to="/">
              <img src={scigenomeLogo} className=" w-40" alt="logo" />
            </Link>
          </h1>
        </div>
        <div className="navbar-menu hidden lg:block">
          <ul className="flex gap-6 text-white items-center">
            <Link to="/">
              <li
                onClick={() => setActiveNavMenu("home")}
                className={`cursor-pointer ${
                  activeNavMenu === "home" ? "active" : ""
                }`}
              >
                Home
              </li>
            </Link>
            <Link to="/about">
              <li
                onClick={() => setActiveNavMenu("about")}
                className={`cursor-pointer ${
                  activeNavMenu === "about" ? "active" : ""
                }`}
              >
                About Us
              </li>
            </Link>
            <Link to="/portfolio">
              <li
                onClick={() => setActiveNavMenu("portfolio")}
                className={`cursor-pointer ${
                  activeNavMenu === "portfolio" ? "active" : ""
                }`}
              >
                Portfolio
              </li>
            </Link>
            <Link to="/career">
              <li
                onClick={() => setActiveNavMenu("career")}
                className={`cursor-pointer ${
                  activeNavMenu === "career" ? "active" : ""
                }`}
              >
                Career
              </li>
            </Link>
            <Link to="/publications">
              <li
                onClick={() => setActiveNavMenu("publications")}
                className={`cursor-pointer ${
                  activeNavMenu === "publications" ? "active" : ""
                }`}
              >
                Publications
              </li>
            </Link>
            <Link to="/news">
              <li
                onClick={() => setActiveNavMenu("news")}
                className={`cursor-pointer ${
                  activeNavMenu === "news" ? "active" : ""
                }`}
              >
                News
              </li>
            </Link>

            <Link to="/contact">
              <li
                onClick={() => setActiveNavMenu("contact")}
                className={`cursor-pointer ${
                  activeNavMenu === "contact" ? "active" : ""
                }`}
              >
                Contact
              </li>
            </Link>
            <Link to="/#sgrf">
              <div className="cursor-pointer SGRF px-3 py-2">
                SciGenom Research Foundation
              </div>
            </Link>
          </ul>
        </div>
        {mobile && (
          <div className="navbar-menu-mobile absolute top-16 right-10 lg:hidden scaleUp">
            <ul className="flex flex-col px-8 py-3 rounded-md bg-slate-500 gap-6 text-white">
              <Link to="/">
                <li className="cursor-pointer">Home</li>
              </Link>
              <Link to="/about">
                <li className="cursor-pointer">About Us</li>
              </Link>
              <Link to="/#portfolio">
                <li className="cursor-pointer">Portfolio</li>
              </Link>
              <Link to="/career">
                <li className="cursor-pointer">Career</li>
              </Link>
              <Link to="/publications">
                <li className="cursor-pointer">Publications</li>
              </Link>
              <Link to="/news">
                <li className="cursor-pointer">News</li>
              </Link>
              <Link to="/contact">
                <li className="cursor-pointer">Contact</li>
              </Link>
              <Link to="/#sgrf">
                <div className="cursor-pointer SGRF px-3 py-2">
                  SciGenom Research Foundation
                </div>
              </Link>
            </ul>
          </div>
        )}
        <div
          className="mobilenav lg:hidden"
          onClick={() => setMobile((prev) => !prev)}
        >
          {!mobile && <Menu className="text-white cursor-pointer" />}
          {mobile && <Close className="text-white cursor-pointer" />}
        </div>
      </div>
    </>
  );
};

export default Navbar;
