import React from "react";
import CloseIcon from "@mui/icons-material/Close";

const AboutShaneMiersch = ({ activeTopBar, setActiveTopBar }) => {
  return (
    <div className="bg-[#fff] rounded-tl-xl rounded-tr-xl rounded-br-xl rounded-bl-xl text-black absolute w-6/12 z-10 topBar">
      <div className="header flex justify-between mb-4 bg-[#061D3A] text-white items-center px-8 py-3 rounded-tl-xl rounded-tr-xl">
        <h1 className=" text-2xl m-0">Dr. Shane Miersch</h1>
        <div
          className="close cursor-pointer"
          onClick={() => setActiveTopBar("")}
        >
          <CloseIcon />
        </div>
      </div>
      <div className="content readmore px-8 py-5">
        <p className="mb-4">
Dr. Miersch is a protein engineer with over 15 years of experience in proteomics and techniques for engineering and optimizing antibody function. He obtained his PhD from the University of Windsor and conducted his post-doctoral studies at the Harvard Institute of Proteomics before joining Dr. Sachdev Sidhu at the Toronto Recombinant Antibody Centre. As a Senior Research Associate there, he has led multi-institutional, inter-disciplinary and international efforts with the National Institutes of Health, Jing Medicine, Reflexion Pharmaceuticals, and Washington State University coordinating collaborative efforts aimed at developing antibodies and non-antibody scaffolds for therapeutic applications. His work has focused on engineering and optimizing functional and biophysical properties via the use of degenerate libraries and directed evolution, grafting of protein-protein interaction domains, site-specific modification of proteins and tailored selections on protein homologs and multi-protein complexes. More recently, these efforts have led to the development of novel host- and virus-directed, multivalent and multi-specific anti-viral antibody formats whose functional properties exceed those of bivalent antibodies and is currently focused on developing these for clinical application. He has authored and co-authored >30 publications in peer reviewed journals including Cell and the Journal of Molecular Biology, and is an inventor on 9 patent applications filed.
        </p>
      </div>
    </div>
  );
};

export default AboutShaneMiersch;
