import React from "react";
import iso_registered from "../../assets/images/iso-icon.png";
import dsir from "../../assets/images/dsir-icon.png";
import "./footer.css";

const Footer = ({ setShowIso }) => {
  return (
    <div className="px-4 md:px-7 lg:px-32 py-3 w-full bg-[#061d3a] text-white">
      <div className="iso_reg flex flex-wrap items-center">
        <div className="left w-full md:w-4/12 flex gap-5 justify-center md:justify-start">
          <img
            onClick={() => setShowIso((prev) => !prev)}
            src={iso_registered}
            alt=""
            className=" cursor-pointer hover:scale-100 transition-all"
          />
          <img
            src={dsir}
            alt=""
            className=" cursor-pointer hover:scale-100 transition-all"
          />
        </div>
        <div className="middle w-full md:w-4/12 mt-2">
          <p className=" text-xs text-center">
            Copyright © - 2020 SciGenom Labs Pvt. Ltd - All Rights Reserved
          </p>
        </div>
        <div className="right w-full md:w-4/12 mt-2 flex justify-center md:justify-end">
          <div className="socialMedia flex gap-2">
            <a href="#" target="_blank">
              <div className="fb icon"></div>
            </a>
            <a href="#" target="_blank">
              <div className="insta icon"></div>
            </a>
            <a href="#" target="_blank">
              <div className="twitter icon"></div>
            </a>
            <a href="#" target="_blank">
              <div className="youtube icon"></div>
            </a>
            <a href="#" target="_blank">
              <div className="medium icon"></div>
            </a>
            <a href="#" target="_blank">
              <div className="linkedin icon"></div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
