import React from "react";
import Scientific from "../../assets/logos/publications/Scientific-reports.jpg";
import indian from "../../assets/logos/publications/indian-journal-of-human-genetics.jpg";
import plos1 from "../../assets/logos/publications/plos-one.jpg";
import antimicrobial from "../../assets/logos/publications/journal-of-antimicrobial-chemotherapy.jpg";
import Fiid from "../../assets/logos/publications/Fiid-biotechnology.jpg";
import BMC from "../../assets/logos/publications/BMC.jpg";

const Publication2015 = () => {
  return (
    <div className="publications2015 mt-5">
      <div className="wrapper bg-white rounded-md p-5">
        <h1 className=" pb-4 mb-4 border-b text-lg">
          Whole genome sequencing and analysis of Swarna, a widely cultivated
          indica rice variety with low glycemic index
        </h1>
        <div className="flex justify-between items-center gap-4">
          <div className="left w-2/12">
            <img src={Scientific} alt="" />
          </div>
          <div className="right w-10/12">
            <p className=" text-base font-normal mb-4">
              Pasupathi Rathinasabapathi, Natarajan Purushothaman, Ramprasad VL
              and Madasamy Parani
            </p>
            <a
              className=" text-blue-500"
              href="https://www.nature.com/articles/srep11303"
              target="_blank"
            >
              DOI:10.1038/srep11303
            </a>
          </div>
        </div>
      </div>
      <div className="wrapper bg-white rounded-md p-5 mt-5">
        <h1 className=" pb-4 mb-4 border-b text-lg">
          Prevalence of genetic variants associated with cardiovascular disease
          risk and drug response in the Southern Indian population of Kerala
        </h1>
        <div className="flex justify-between items-center gap-4">
          <div className="left w-2/12">
            <img src={indian} alt="" />
          </div>
          <div className="right w-10/12">
            <p className=" text-base font-normal mb-4">
              Lakshmi Mahadevan, Ancy Yesudas, P. K. Sajesh, S. Revu, Prasanna
              Kumar, Devi Santhosh, Sam Santhosh, J. M. Sashikumar,1 V. K.
              Gopalakrishnan,Joji Boben and Changanamkandath Rajesh
            </p>
            <a
              className=" text-blue-500"
              href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4228570/"
              target="_blank"
            >
              DOI:10.4103/0971-6866.142896
            </a>
          </div>
        </div>
      </div>
      <div className="wrapper bg-white rounded-md p-5 mt-5">
        <h1 className=" pb-4 mb-4 border-b text-lg">
          Whole genome sequencing and analysis of plant growth promoting
          bacteria isolated from the rhizosphere of plantation crops coconut,
          cocoa and arecanut
        </h1>
        <div className="flex justify-between items-center gap-4">
          <div className="left w-2/12">
            <img src={plos1} alt="" />
          </div>
          <div className="right w-10/12">
            <p className=" text-base font-normal mb-4">
              Alka Gupta, Murali Gopal, George V. Thomas, Vinu Manikandan, John
              Gajewski, George Thomas, Somasekar Seshagiri, Stephan C.
              Schuster,P reeti Rajesh , Ravi Gupta
            </p>
            <a
              className=" text-blue-500"
              href="https://doi.org/10.1371/journal.pone.0104259"
              target="_blank"
            >
              DOI:10.1371/journal.pone.0104259
            </a>
          </div>
        </div>
      </div>
      <div className="wrapper bg-white rounded-md p-5 mt-5">
        <h1 className=" pb-4 mb-4 border-b text-lg">
          Cost-efficient HIV-1 drug resistance surveillance using multiplexed
          high-throughput amplicon sequencing: implications for use in low- and
          middle-income countries
        </h1>
        <div className="flex justify-between items-center gap-4">
          <div className="left w-2/12">
            <img src={antimicrobial} alt="" />
          </div>
          <div className="right w-10/12">
            <p className=" text-base font-normal mb-4">
              Halime Ekici, Shwetha D. Rao, Anders Sönnerborg, Vedam L.
              Ramprasad, Ravi Gupta, Ujjwal Neogi
            </p>
            <a
              className=" text-blue-500"
              href="https://doi.org/10.1093/jac/dku278"
              target="_blank"
            >
              DOI:10.1093/jac/dku278
            </a>
          </div>
        </div>
      </div>
      <div className="wrapper bg-white rounded-md p-5 mt-5">
        <h1 className=" pb-4 mb-4 border-b text-lg">
          Transcriptome profiling reveals higher vertebrate orthologous of
          intra-cytoplasmic pattern recognition receptors in grey bambooo shark
        </h1>
        <div className="flex justify-between items-center gap-4">
          <div className="left w-2/12">
            <img src={plos1} alt="" />
          </div>
          <div className="right w-10/12">
            <p className=" text-base font-normal mb-4">
              Tirumurugaan Krishnaswamy Gopalan, Pradheepa Gururaj, Ravi
              Gupta,Dhinakar Raj Gopal, Preeti Rajesh,Balachandran Chidambaram,
              Aravindan Kalyanasundaram,Raja Angamuthu
            </p>
            <a
              className=" text-blue-500"
              href="https://doi.org/10.1371/journal.pone.0100018"
              target="_blank"
            >
              DOI:10.1371/journal.pone.0100018
            </a>
          </div>
        </div>
      </div>
      <div className="wrapper bg-white rounded-md p-5 mt-5">
        <h1 className=" pb-4 mb-4 border-b text-lg">
          DNA Barcoding to Detect Chilli Adulteration in Traded Black Pepper
          Powder
        </h1>
        <div className="flex justify-between items-center gap-4">
          <div className="left w-2/12">
            <img src={Fiid} alt="" />
          </div>
          <div className="right w-10/12">
            <p className=" text-base font-normal mb-4">
              V. A. Parvathy, V. P. Swetha, T. E. Sheeja, N. K. Leela, B.
              Chempakam & B. Sasikumar
            </p>
            <a
              className=" text-blue-500"
              href="https://doi.org/10.1080/08905436.2013.870078"
              target="_blank"
            >
              DOI:10.1080/08905436.2013.870078
            </a>
          </div>
        </div>
      </div>
      <div className="wrapper bg-white rounded-md p-5 mt-5">
        <h1 className=" pb-4 mb-4 border-b text-lg">
          Sequencing and analysis of a South Asian-Indian personal genome
        </h1>
        <div className="flex justify-between items-center gap-4">
          <div className="left w-2/12">
            <img src={BMC} alt="" />
          </div>
          <div className="right w-10/12">
            <p className=" text-base font-normal mb-4">
              Ravi Gupta, Aakrosh Ratan, Changanamkandath Rajesh, Rong Chen, Hie
              Lim Kim, Richard Burhans, Webb Miller, Sam Santhosh, Ramana V
              Davuluri, Atul J Butte, Stephan C Schuster, Somasekar Seshagiri &
              George Thomas
            </p>
            <a
              className=" text-blue-500"
              href="https://doi.org/10.1186/1471-2164-13-440"
              target="_blank"
            >
              DOI:10.1186/1471-2164-13-440
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Publication2015;
