import React, { useEffect, useMemo } from "react";

const useElementOnScreen = (options) => {
  const optionMemo = useMemo(() => {
    return options;
  }, [options]);

  useEffect(() => {
    const banner = document.querySelectorAll(".banner");
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("show");
          observer.unobserve(entry.target);
        }
      });
    }, optionMemo);
    banner.forEach((element) => {
      observer.observe(element);
    });
  }, [options, optionMemo]);
};

export default useElementOnScreen;
