import React from "react";
import sgrflogo from "../../assets/logos/portfolio/sgrf_logo.png";
import bookCover from "../../assets/images/book_cover.png";

const SgrfSection = () => {
  return (
    <section className="observeClass sgrf min-h-screen bg-white" id="sgrf">
      <div className="topSection min-h-[100vh] flex flex-wrap">
        <div className="left w-full md:w-6/12 flex justify-center items-center pt-32 md:pt-0 pb-5">
          <div className="contentWrapper w-full pl-4 md:pl-7 lg:pl-32 text-white pr-4">
            <div className="logo mb-16">
              <img src={sgrflogo} alt="" />
            </div>
            <div className="content mb-8">
              <p className=" text-2xl font-medium">
                Over 25 scientific papers published!
              </p>
            </div>
            <div className=" company mb-10">
              <h1 className=" text-3xl font-semibold mb-2">
                SciGenom Research Foundation
              </h1>
              <p className=" text-sm">
                Non-profit foundation promoting science in India through
                research & education
              </p>
            </div>
            <div className="linkButton mb-4 md:mb-0">
              <a
                href="http://www.sgrf.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className=" px-3 py-2 border rounded-full">
                  www.sgrf.org
                </button>
              </a>
            </div>
          </div>
        </div>
        <div className="right w-full md:w-6/12 flex justify-center items-center pb-5">
          <div className="contentWrapper w-full pl-3 md:pl-5 lg:pl-10 text-white pt-16">
            <div className="title flex justify-center mb-2">
              <h1 className="inline-block mx-auto text-3xl border-b-2 py-2">
                Research Areas
              </h1>
            </div>
            <div className="reasearchAreaslogos flex justify-between mb-3">
              <div className="img flex items-center flex-col">
                <div className="reasearch_areas1 mb-2"></div>
                <div className="text text-center text-sm">
                  <p className=" text-xs">
                    Hortus Malabaricus Medicinal Plant Collection
                  </p>
                </div>
              </div>
              <div className="img flex items-center flex-col">
                <div className="reasearch_areas2 mb-2"></div>
                <div className="text text-center text-sm">
                  <p className=" text-xs">
                    Tea Looper Biopesticide Development
                  </p>
                </div>
              </div>
              <div className="img flex items-center flex-col">
                <div className="reasearch_areas3 mb-2"></div>
                <div className="text text-center text-sm">
                  <p className=" text-xs">
                    Wild Elephant Viral Disease Genomics
                  </p>
                </div>
              </div>
              <div className="img flex items-center flex-col">
                <div className="reasearch_areas4 mb-2"></div>
                <div className="text text-center text-sm">
                  <p className=" text-xs">Human Variation Project</p>
                </div>
              </div>
              <div className="img flex items-center flex-col">
                <div className="reasearch_areas5 mb-2"></div>
                <div className="text text-center text-sm">
                  <p className=" text-xs">Cobra Genome and Venom Toxins</p>
                </div>
              </div>
            </div>
            <div className="bookCover">
              <img src={bookCover} alt="" />
            </div>

            <div className="portfolio-sprite flex">
              <div className="img flex items-center flex-col">
                <div className="founded mb-2"></div>
                <div className="text text-center text-sm">
                  <span className=" font-medium">Founded</span> <br />
                  2010
                </div>
              </div>
              <div className="img flex items-center flex-col">
                <div className="location mb-2"></div>
                <div className="text text-center text-sm">
                  <span className=" font-medium">Location</span> <br />
                  Kerala, India
                </div>
              </div>
              <div className="img flex items-center flex-col">
                <div className="people mb-2"></div>
                <div className="text text-center text-sm">
                  <span className=" font-medium">People</span> <br />
                  15
                </div>
              </div>
              <div className="img flex items-center flex-col">
                <div className="ledby mb-2"></div>
                <div className="text text-center text-sm">
                  <span className=" font-medium">Led By</span> <br />
                  Dr. Sekar Seshagiri
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SgrfSection;
