import React from "react";
import CloseIcon from "@mui/icons-material/Close";

const AboutCNR = ({ activeTopBar, setActiveTopBar }) => {
  return (
    <div className="bg-[#fff] rounded-tl-xl rounded-tr-xl rounded-br-xl rounded-bl-xl text-black absolute w-6/12 z-10 topBar">
      <div className="header flex justify-between mb-4 bg-[#061D3A] text-white items-center px-8 py-3 rounded-tl-xl rounded-tr-xl">
        <h1 className=" text-2xl m-0">Dr. Manoj Krishnan</h1>
        <div
          className="close cursor-pointer"
          onClick={() => setActiveTopBar("")}
        >
          <CloseIcon />
        </div>
      </div>
      <div className="content readmore px-8 py-5">
        <p className="mb-4">
          Manoj Krishnan obtained his PhD from Kerala, in biophysics, and
          completed his postdoctoral training at the Yale university in genetic
          regulation of human immune response. Subsequently he worked at the
          Duke-NUS Medical School, Singapore, as a faculty member. At the
          Duke-NUS he led a laboratory conducting research on understanding the
          role and regulation of immune systems in inflammatory conditions,
          and the discovery of immunotherapeutic targets.
        </p>
        <p className="">
          He also worked at the Mammalian pharma foundry of Ginkgo Bioworks,
          focusing on developing gene and cell therapy approaches using
          synthetic biology. Currently Dr. Krishnan is working at the Manus Bio
          as the head of pharma, focusing on developing novel biomanufacturing
          platforms for unmet drug modalities.
        </p>
      </div>
    </div>
  );
};

export default AboutCNR;
