import React from "react";
import CloseIcon from "@mui/icons-material/Close";

const AboutAmitChaudhuri = ({ activeTopBar, setActiveTopBar }) => {
  return (
    <div className="bg-[#fff] rounded-tl-xl rounded-tr-xl rounded-br-xl rounded-bl-xl text-black absolute w-6/12 z-10 topBar">
      <div className="header flex justify-between mb-4 bg-[#061D3A] text-white items-center px-8 py-3 rounded-tl-xl rounded-tr-xl">
        <h1 className=" text-2xl m-0">Dr. Amit Chaudhuri</h1>
        <div
          className="close cursor-pointer"
          onClick={() => setActiveTopBar("")}
        >
          <CloseIcon />
        </div>
      </div>
      <div className="content readmore px-8 py-5">
        <p className=" mb-3">
          Dr. Amit Chaudhuri possesses over two decades of experience in the
          field of drug discovery and technology advancement, specializing in
          high throughput proteomics and genomics technologies. He has held
          positions at prominent companies such as CuraGen Corporation,
          Genentech/Roche, and most recently, MedGenome.
        </p>
        <p className=" mb-3">
          During his tenure at Genentech, Dr. Chaudhuri played a crucial role
          in early discovery research, focusing on the identification and
          validation of therapeutic targets through cell-based assays and
          animal models.
        </p>
        <p className=" mb-3">
          At MedGenome, Dr. Chaudhuri leveraged Next Generation sequencing
          techniques to gain valuable insights into vaccine development for
          improved treatment of cancer and infectious diseases. Notably, under
          Dr. Chaudhuri's guidance, the Immuno-oncology team at MedGenome
          successfully developed an integrated platform for the discovery and
          creation of cancer vaccines.
        </p>
        <p className=" mb-3">
          Dr. Chaudhuri has over 30 publications in peer reviewed journals and
          an issued patent on the discovery of novel T cell epitopes.
        </p>
      </div>
    </div>
  );
};

export default AboutAmitChaudhuri;
