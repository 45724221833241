import React, { useEffect } from "react";
import healthworld from "../../assets/logos/news/et_hw.jpg";
import livemint from "../../assets/logos/news/livemint.jpg";
import outlook from "../../assets/logos/news/outlook.jpg";
import businessstd from "../../assets/logos/news/businessstd.jpg";
import indianexpress from "../../assets/logos/news/indianexpress.jpg";
import timesofindia from "../../assets/logos/news/timesofindia.jpg";
import thehindu from "../../assets/logos/news/thehindu.jpg";
import biospectrumasia from "../../assets/logos/news/biospectrumasia.jpg";
import plos from "../../assets/logos/news/plos.jpg";
import prnews from "../../assets/logos/news/prnews.jpg";
import indianjournal from "../../assets/logos/news/indianjournal.jpg";
import pharmabiz from "../../assets/logos/news/pharmabiz.jpg";
import finance from "../../assets/logos/news/finance.jpg";
import futuremedicineonline from "../../assets/logos/news/futuremedicineonline.jpg";
import financialexpress from "../../assets/logos/news/financialexpress.jpg";
import "./news.css";

const News = () => {
  useEffect(() => {
    const observeElement = document.querySelectorAll(".newsContent");
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("show");
            observer.unobserve(entry.target);
          }
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );
    observeElement.forEach((element) => {
      observer.observe(element);
    });
  }, []);
  return (
    <div className="px-4 md:px-7 lg:px-32 pt-24">
      <h1 className=" text-white text-xl sm:text-2xl md:text-3xl lg:text-4xl mb-5">
        News
      </h1>
      <div className="newsContainer max-h-[72vh] overflow-y-auto">
        <div className="newsContent p-1 bg-white col-span-2 flex">
          <div className="newsLogo w-3/12 flex justify-center items-center border">
            <img src={healthworld} alt="" />
          </div>
          <div className=" w-9/12 border p-3">
            <h1 className="text-2xl font-normal mb-3">January 2020</h1>
            <h3 className="text-base text-black mb-3 font-bold">
              Indian cobra genome and venom genes decoded
            </h3>
            <p className=" font-normal">
              In the January 2020 issue of Nature Genetics, scientists from
              SciGenom Research Foundation (SGRF), India, AgriGenome Labs,
              India, MedGenome, India/USA, SciGenom, India, and Genentech, a
              member of the Roche group, along with a team of international
              collaborators from academia and industry report the sequencing and
              assembly of a high-quality genome of the highly venomous,
              medically important Indian cobra (Naja naja).
              <a
                className=" text-sky-700 ml-5 ml-5"
                target="_blank"
                href="https://health.economictimes.indiatimes.com/news/industry/indian-cobra-genome-and-venom-genes-decoded/73131025"
              >
                View More
              </a>
            </p>
          </div>
        </div>
        <div className="newsContent bg-white col-span-2 p-1 flex">
          <div className="newsLogo flex justify-center items-center border w-3/12">
            <img src={livemint} alt="" />
          </div>
          <div className="w-9/12 border p-3">
            <h1 className="text-2xl font-normal mb-3">December 2019</h1>
            <h3 className="text-base text-black mb-3 font-bold">
              Study finds Thalasemmia, cancer genes exclusive to Indian
              population
            </h3>
            <p className=" font-normal">
              The concept of unity in diversity best fits India, not only for
              its culture, society and religion, but also for its gene pool.
              Research has revealed that certain Thalasemmia, a blood disorder,
              and genetic markers are exclusive to Indian population. It has
              also found that for Indians, genetic similarity makes them more
              susceptible to risk of diseases
              <a
                className=" text-sky-700 ml-5 ml-5"
                target="_blank"
                href="https://www.livemint.com/science/health/study-finds-thalasemmia-cancer-genes-exclusive-to-indian-population-11575547391671.html"
              >
                View More
              </a>
            </p>
          </div>
        </div>
        <div className="newsContent bg-white p-1 flex">
          <div className="newsLogo w-3/12 flex justify-center items-center border">
            <img src={outlook} alt="" />
          </div>
          <div className=" w-9/12 border p-3">
            <h1 className="text-2xl font-normal mb-3">December 2019</h1>
            <h3 className="text-base text-black mb-3 font-bold">
              Asia has at least 10 ancestral lineages, shows genome mapping
            </h3>
            <p className=" font-normal">
              Asia has at least 10 ancestral lineages, whereas northern Europe
              has just one, says a study of global genetic comparison including
              genome sequence from 598 individuals belonging to 55 ethnic groups
              from India.
              <a
                className=" text-sky-700 ml-5 ml-5"
                target="_blank"
                href="https://www.outlookindia.com/newsscroll/asia-has-at-least-10-ancestral-lineages-shows-genome-mapping/1678236"
              >
                View More
              </a>
            </p>
          </div>
        </div>
        <div className="newsContent bg-white p-1 flex">
          <div className="newsLogo w-3/12 flex justify-center items-center border">
            <img src={indianexpress} alt="" />
          </div>
          <div className="w-9/12 border p-3">
            <h1 className="text-2xl font-normal mb-3">Feb 2018</h1>
            <h3 className="text-base text-black mb-3 font-bold">
              Developing advanced therapeutics for cancer:
            </h3>
            <p className=" font-normal">
              KSIDC chairman Christy Fernandez with Brenda Andrews, professor,
              University of Toronto and Sam Santhosh, chairman, SciGenome Labs
              at the Indo-US -Canada biotechnology joint venture conference for
              the
              <a
                className=" text-sky-700 ml-5"
                target="_blank"
                href="http://www.newindianexpress.com/cities/thiruvananthapuram/2018/feb/02/developing-advanced-therapeutics-for-cancer-1767398.html"
              >
                View More
              </a>
            </p>
          </div>
        </div>
        <div className="newsContent col-span-2 bg-white p-1 flex">
          <div className="newsLogo w-3/12 flex justify-center items-center border">
            <img src={businessstd} alt="" />
          </div>
          <div className="w-9/12 border p-3">
            <h1 className="text-2xl font-normal mb-3">March 2018</h1>
            <h3 className="text-base text-black mb-3 font-bold">
              MedGenome offers research and diagnostics in complex gene-related
              maladies
            </h3>
            <p className=" font-normal">
              Fascinated with the idea of the “source code of life”, Sam
              Santhosh, founder of MedGenome, spent a little more than seven
              years in decoding the cause of genomic diseases and finding
              solutions for these. His research came after completion of the
              human genome project created waves in the health care and
              scientific world in 2003.
              <a
                className=" text-sky-700 ml-5"
                target="_blank"
                href="https://www.business-standard.com/article/companies/medgenome-offers-research-and-diagnostics-in-complex-gene-related-maladies-118032500639_1.html"
              >
                View More
              </a>
            </p>
          </div>
        </div>
        <div className="newsContent col-span-2 bg-white p-1">
          <div className="newsLogo w-3/12 flex justify-center items-center border">
            <img src={timesofindia} alt="" />
          </div>
          <div className="w-9/12 border p-3">
            <h1 className="text-2xl font-normal mb-3">Sep 2017</h1>
            <h3 className="text-base text-black mb-3 font-bold">
              Never settle for mediocrity; it is worse than failure:
            </h3>
            <p className=" font-normal">
              Gene sequencing firm Medgenome last week raised $30 million from
              several venture firms, as also from Infosys co-founder Kris
              Gopalakrishnan and former Cognizant CEO Lakshmi Narayanan. The
              Bengaluru-based firm is one of the biggest such firms in India,
              helping find solutions for genetic diseases
              <a
                className=" text-sky-700 ml-5"
                target="_blank"
                href="https://timesofindia.indiatimes.com/people/sam-santhosh-medgenome-interview/articleshow/60338025.cms"
              >
                View More
              </a>
            </p>
          </div>
        </div>
        <div className="newsContent col-span-2 bg-white p-1">
          <div className="newsLogo w-3/12 flex justify-center items-center border">
            <img src={thehindu} alt="" />
          </div>
          <div className="w-9/12 border p-3">
            <h1 className="text-2xl font-normal mb-3">October 2016</h1>
            <h3 className="text-base text-black mb-3 font-bold">
              Uncovering the genetic secrets of purple rice
            </h3>
            <p className=" font-normal">
              The mysterious ways of genes influencing the character of crop
              plants through long periods of domestication, selection and modern
              breeding continues to perplex genomics specialists, as found out
              by the genome researchers working on coloured rice, Purpleputtu
              recently
              <a
                className=" text-sky-700 ml-5"
                target="_blank"
                href="https://www.thehindu.com/sci-tech/agriculture/Uncovering-the-genetic-secrets-of-purple-rice/article16072976.ece"
              >
                View More
              </a>
            </p>
          </div>
        </div>
        <div className="newsContent col-span-2 bg-white p-1">
          <div className="newsLogo w-3/12 flex justify-center items-center border">
            <img src={thehindu} alt="" />
          </div>
          <div className="w-9/12 border p-3">
            <h1 className="text-2xl font-normal mb-3">October 2016</h1>
            <p className=" font-normal">
              A second innings is about a new beginning in any field, not just
              the cricket field. For Sam Santhosh, Silicon Valley entrepreneur
              from the State, it is about pursuing his new-found passion to
              understand the complexities of the genomes of the earth, a path
              completely different from his earlier IT-based interests such as
              the software industry. It is also about his fascination for
              prehistoric life inspired by his travels to remote archaeological
              digs
              <a
                className=" text-sky-700 ml-5"
                target="_blank"
                href="http://www.thehindu.com/todays-paper/tp-features/tp-metroplus/sams-world-of-science/article9212524.ece"
              >
                View More
              </a>
            </p>
          </div>
        </div>
        <div className="newsContent col-span-2 bg-white p-1">
          <div className="newsLogo w-3/12 flex justify-center items-center border">
            <img src={indianexpress} alt="" />
          </div>
          <div className="w-9/12 border p-3">
            <h1 className="text-2xl font-normal mb-3">March 2015</h1>
            <p className=" font-normal">
              Dr. Milner Kumar's invited talk at the National Seminar on
              'Bioinformatics in Marine Biology: Next Generation Sequencing
              Technologies and Applications' at the School of Marine Sciences,
              organized by the Department of Marine Biology, Microbiology and
              Biochemistry, Cochin University of Science and Technology.
              <a
                className=" text-sky-700 ml-5"
                target="_blank"
                href="http://www.newindianexpress.com/cities/kochi/National-Seminar-at-Cusat/2015/03/14/article2712322.ece"
              >
                View More
              </a>
            </p>
          </div>
        </div>
        <div className="newsContent col-span-2 bg-white p-1">
          <div className="newsLogo w-3/12 flex justify-center items-center border">
            <img src={biospectrumasia} alt="" />
          </div>
          <div className="w-9/12 border p-3">
            <h1 className="text-2xl font-normal mb-3">November 2014</h1>
            <p className=" font-normal">
              SciGenom Research Foundation (SGRF), a not-for-profit arm of
              SciGenom Labs, organized a three-day conference on November 17-19,
              2014, titled 'NextGen Genomics & Bioinformatics Technologies
              (NGBT)', along with NIMHANS and IOB, in Bangalore.
              <a
                className=" text-sky-700 ml-5"
                target="_blank"
                href="http://www.biospectrumindia.com/biospecindia/news/219735/-indian-students-career-revolve-it/page/2"
              >
                View More
              </a>
            </p>
          </div>
        </div>
        <div className="newsContent col-span-2 bg-white p-1">
          <div className="newsLogo w-3/12 flex justify-center items-center border">
            <img src={plos} alt="" />
          </div>
          <div className="w-9/12 border p-3">
            <h1 className="text-2xl font-normal mb-3">August 2014</h1>
            <p className=" font-normal">
              SciGenom's collaborative research project with the CPCRI, Kasargod
              and Dr. Schuster's lab at NTU, Singapore, on Whole Genome
              Sequencing of Plantation Crops growth promoting bacteria,
              published in PLOS One.
              <a
                className=" text-sky-700 ml-5"
                target="_blank"
                href="http://journals.plos.org/plosone/article?id=10.1371/journal.pone.0104259"
              >
                View More
              </a>
            </p>
          </div>
        </div>
        <div className="newsContent bg-white p-1">
          <div className="newsLogo w-3/12 flex justify-center items-center border">
            <img src={prnews} alt="" />
          </div>
          <div className="w-9/12 border p-3">
            <h1 className="text-2xl font-normal mb-3">August 2014</h1>
            <p className=" font-normal">
              SciGenom Labs covered as part of a global report on DNA
              sequencing: Emerging Technologies and Application
              <a
                className=" text-sky-700 ml-5"
                target="_blank"
                href="http://www.prnewswire.com/news-releases/dna-sequencing-emerging-technologies-and-applications-270156351.html"
              >
                View More
              </a>
            </p>
          </div>
        </div>
        <div className="newsContent bg-white p-1">
          <div className="newsLogo w-3/12 flex justify-center items-center border">
            <img src={biospectrumasia} alt="" />
          </div>
          <div className="w-9/12 border p-3">
            <h1 className="text-2xl font-normal mb-3">June 2014</h1>
            <p className=" font-normal">
              Mr. Sam Santhosh, CEO SciGenom Labs quoted in BioSpectrum article
              on Genomics application picking pace in India.
              <a
                className=" text-sky-700 ml-5"
                target="_blank"
                href="http://www.biospectrumindia.com/biospecindia/features/215944/genomics-picking-pace-india"
              >
                View More
              </a>
            </p>
          </div>
        </div>
        <div className="newsContent col-span-2 bg-white p-1">
          <div className="newsLogo w-3/12 flex justify-center items-center border">
            <img src={indianjournal} alt="" />
          </div>
          <div className="w-9/12 border p-3">
            <h1 className="text-2xl font-normal mb-3">June 2014</h1>
            <p className=" font-normal">
              SciGenom's study with collaborators from St. Thomas Hospital and
              Karpagam University on the prevalence of five variants associated
              with cardiovascular diseases in Kerala, published in the Indian
              Journal of Human Genetics
              <a
                className=" text-sky-700 ml-5"
                target="_blank"
                href="http://www.ijhg.com/article.asp?issn=0971-6866;year=2014;volume=20;issue=2;spage=175;epage=184;aulast=Mahadevan"
              >
                View More
              </a>
            </p>
          </div>
        </div>
        <div className="newsContent bg-white p-1">
          <div className="newsLogo w-3/12 flex justify-center items-center border">
            <img src={biospectrumasia} alt="" />
          </div>
          <div className="w-9/12 border p-3">
            <h1 className="text-2xl font-normal mb-3">April 2014</h1>
            <p className=" font-normal">
              MedGenome Labs is a Cochin-based new start-up on the block, which
              has been incubated at SciGenom Labs for two years before being
              spun out in June 2013
              <a
                className=" text-sky-700 ml-5"
                target="_blank"
                href="http://www.biospectrumasia.com/biospectrum/influencers/213372/-start-services-meet-customers-needs#.U2nxCPmSwYA"
              >
                View More
              </a>
            </p>
          </div>
        </div>
        <div className="newsContent bg-white p-1">
          <div className="newsLogo w-3/12 flex justify-center items-center border">
            <img src={pharmabiz} alt="" />
          </div>
          <div className="w-9/12 border p-3">
            <h1 className="text-2xl font-normal mb-3">November 2013</h1>
            <p className=" font-normal">
              <strong>SciGenom</strong> Labs embarks on mitochondria sequencing,
              reports faster diagnosis of certain diseases
              <a
                className=" text-sky-700 ml-5"
                target="_blank"
                href="http://pharmabiz.com/NewsDetails.aspx?aid=79001&sid=1"
              >
                View More
              </a>
            </p>
          </div>
        </div>
        <div className="newsContent bg-white p-1">
          <div className="newsLogo w-3/12 flex justify-center items-center border">
            <img src={finance} alt="" />
          </div>
          <div className="w-9/12 border p-3">
            <h1 className="text-2xl font-normal mb-3">October 2013</h1>
            <p className=" font-normal">
              Accel Frontline inks MoU with <strong>SciGenom</strong> Labs
              <a
                className=" text-sky-700 ml-5"
                target="_blank"
                href="https://in.finance.yahoo.com/news/accel-frontline-inks-mou-scigenom-090646107.html"
              >
                View More
              </a>
            </p>
          </div>
        </div>
        <div className="newsContent bg-white p-1">
          <div className="newsLogo w-3/12 flex justify-center items-center border">
            <img src={futuremedicineonline} alt="" />
          </div>
          <div className="w-9/12 border p-3">
            <h1 className="text-2xl font-normal mb-3">April 2013</h1>
            <p className=" font-normal">
              CEO Sam Santhosh 'Genome man' of India talks to Future Medicine.
              <a
                className=" text-sky-700 ml-5"
                target="_blank"
                href="http://www.futuremedicineonline.com/detail_news.php?id=78"
              >
                View More
              </a>
            </p>
          </div>
        </div>
        <div className="newsContent bg-white p-1">
          <div className="newsLogo w-3/12 flex justify-center items-center border">
            <img src={thehindu} alt="" />
          </div>
          <div className="w-9/12 border p-3">
            <h1 className="text-2xl font-normal mb-3">Oct 2012</h1>
            <p className=" font-normal">
              "The new Malayali world of DNA" Sequencing and Analysis of a South
              Asian-Indian personal Genome
              <a
                className=" text-sky-700 ml-5"
                target="_blank"
                href="http://www.thehindu.com/news/national/kerala/the-new-malayali-world-of-dna/article3959099.ece"
              >
                View More
              </a>
            </p>
          </div>
        </div>
        <div className="newsContent bg-white p-1">
          <div className="newsLogo w-3/12 flex justify-center items-center border">
            <img src={financialexpress} alt="" />
          </div>
          <div className="w-9/12 border p-3">
            <h1 className="text-2xl font-normal mb-3">April 2012</h1>
            <p className=" font-normal">
              SciGenom Introduces Cancer Tests for Early Detection
              <a
                className=" text-sky-700 ml-5"
                target="_blank"
                href="http://healthcare.financialexpress.com/201204/market12.shtml"
              >
                View More
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default News;
