import React, { useState } from "react";
import Banner from "../../components/banner/Banner";
import Banner1 from "../../components/banner1/Banner1";
import Banner2 from "../../components/banner2/Banner2";
import Banner3 from "../../components/banner3/Banner3";
import Banner4 from "../../components/banner4/Banner4";
import Banner5 from "../../components/banner5/Banner5";
import useElementOnScreen from "../../hooks/useElementOnScreen";
import Footer from "../../components/footer/Footer";

const Home = ({ setShowIso }) => {
  useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 0.5,
  });
  return (
    <>
      <Banner />
      <Banner1 />
      <Banner2 />
      <Banner3 />
      <Banner4 />
      <Banner5 />
      <Footer setShowIso={setShowIso} />
    </>
  );
};

export default Home;
