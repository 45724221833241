import { useState, useEffect } from "react";

const useWindowScroll = () => {
  const [scrollY, setScrollY] = useState(0);
  useEffect(() => {
    let isUnmount = false;
    window.addEventListener("scroll", () => {
      if (!isUnmount) {
        setScrollY(window.pageYOffset);
      }
    });
    return () => {
      isUnmount = !isUnmount;
    };
  }, []);

  return scrollY;
};

export default useWindowScroll;
