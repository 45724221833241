import React from "react";
import medgenomeTransition from "../../assets/images/Medgenom_transition.png";
import medlogo from "../../assets/logos/portfolio/Med_logo.png";
import { ArrowRight } from "@mui/icons-material";

const MedgenomeSection = () => {
  return (
    <section
      className="observeClass medgenome min-h-screen bg-white"
      id="medgenome"
    >
      <div className="topSection min-h-[80vh] flex flex-wrap">
        <div className="left w-full md:w-6/12 flex justify-center items-center pt-32 md:pt-0">
          <div className="contentWrapper w-full pl-4 md:pl-7 lg:pl-32 text-white">
            <div className="logo mb-16">
              <img src={medlogo} alt="" />
            </div>
            <div className="content mb-8">
              <p className=" text-base font-medium w-8/12">
                Largest Genomic Sequencing lab in India has processed over
                200,000 samples
              </p>
            </div>
            <div className=" company mb-10">
              <h1 className=" text-3xl font-semibold mb-2">Medgenome</h1>
              <p className=" text-sm">
                India’s leading genetic diagnostics & genomics research company
              </p>
            </div>
            <div className="linkButton mb-4 md:mb-0">
              <a
                href="https://diagnostics.medgenome.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className=" px-3 py-2 border rounded-full">
                  www.medgenome.com
                </button>
              </a>
            </div>
          </div>
        </div>
        <div className="right w-full md:w-6/12 flex justify-center items-center">
          <div className="contentWrapper w-full pl-3 md:pl-5 lg:pl-10 text-white pt-16">
            <div className="vision mb-2">
              <h1 className=" text-lg">Vision:</h1>
              <ul>
                <li className=" text-sm">
                  <ArrowRight fontSize="small" />
                  Bring genetic diagnostics to India
                </li>
                <li className=" text-sm">
                  <ArrowRight fontSize="small" />
                  Help Pharma develop better drugs using Indian genetic data
                </li>
              </ul>
            </div>
            <div className="vision mb-2">
              <h1 className=" text-lg">Strategy:</h1>
              <ul>
                <li className=" text-sm">
                  <ArrowRight fontSize="small" />
                  Develop relevant genetic tests and market them in India
                </li>
                <li className=" text-sm mb-3">
                  <ArrowRight fontSize="small" />
                  Create a pan India research network to generate genetic data
                  to find new drug targets
                </li>
                <li className=" text-sm">
                  <ArrowRight fontSize="small" />
                  Established baseline genomic data of Indian population
                </li>
                <li className=" text-sm">
                  <ArrowRight fontSize="small" />
                  Founded Genome Asia 100K in Singapore
                </li>
                <li className=" text-sm">
                  <ArrowRight fontSize="small" />
                  Introduced NIPT in India
                </li>
                <li className=" text-sm">
                  <ArrowRight fontSize="small" />
                  Developed over 500 genetic tests
                </li>
              </ul>
            </div>

            <div className="portfolio-sprite flex">
              <div className="img flex items-center flex-col">
                <div className="founded mb-2"></div>
                <div className="text text-center text-sm">
                  <span className=" font-medium">Founded</span> <br />
                  2013
                </div>
              </div>
              <div className="img flex items-center flex-col">
                <div className="location mb-2"></div>
                <div className="text text-center text-sm">
                  <span className=" font-medium">Location</span> <br />
                  Foster City, USA Bangalore, India
                </div>
              </div>
              <div className="img flex items-center flex-col">
                <div className="people mb-2"></div>
                <div className="text text-center text-sm">
                  <span className=" font-medium">People</span> <br />
                  500
                </div>
              </div>
              <div className="img flex items-center flex-col">
                <div className="ledby mb-2"></div>
                <div className="text text-center text-sm">
                  <span className=" font-medium">Led By</span> <br />
                  Dr. Ramprasad (India) <br /> Ray Mathoda (US)
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottomSection min-h-[20vh] flex justify-center bg-[#0b2744]">
        <div className="years px-4 md:px-7 lg:px-32 py-5 overflow-x-auto">
          <img src={medgenomeTransition} alt="" />
        </div>
      </div>
    </section>
  );
};

export default MedgenomeSection;
