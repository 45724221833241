import React from "react";
import CloseIcon from "@mui/icons-material/Close";

const AboutCNR = ({ activeTopBar, setActiveTopBar }) => {
  return (
    <div className="bg-[#fff] rounded-tl-xl rounded-tr-xl rounded-br-xl rounded-bl-xl text-black absolute w-6/12 z-10 topBar">
      <div className="header flex justify-between mb-4 bg-[#061D3A] text-white items-center px-8 py-3 rounded-tl-xl rounded-tr-xl">
        <h1 className=" text-2xl m-0">Dr. CN Ramchand</h1>
        <div
          className="close cursor-pointer"
          onClick={() => setActiveTopBar("")}
        >
          <CloseIcon />
        </div>
      </div>
      <div className="content readmore px-8 py-5">
        <p className="mb-4">
Dr. CN. Ramchand has 25 years of extensive experience in the areas of teaching, basic research, drug discovery programme and investigating disease mechanisms, guiding M Sc. / Ph D. students and setting up Research facilities. He is the co-founder, CEO and CSO of MagGenome, a cutting-edge bio-nanotechnology company developing products for the genomics and the proteomics market. He is also the Director of Saksin Lifesciences, Theragen Biologics, Thejo Engineering and Accel limited developing novel therapeutics and products for the life sciences and healthcare industry. Prior to founding Saksin Lifesciences and MagGenome, he served as a Director of R&D at Kemin Industries South Asia Pvt Ltd and prior to that was the VP and Head of Drug Discovery Research where he oversaw the setting up of a multi-million-dollar Drug Discovery Center and led the development and approval of multiple nutraceutical products for India and the global market.
        </p>
        <p className="mb-4">
Dr. Ramchand has published over 70 peer-reviewed papers in international journals, edited 5 books, has around 15 patented products currently in the market and holds close to 60 granted/applied patents. He has done over 100 research presentations at international conferences worldwide and was also a senior Visiting fellow at University of Sheffield and University of New Castle Upon-Tyne, UK, and at Slovakian Academy of Sciences, Slovak Republic. He has a very close research association with State University of New York at Buffalo, USA, Florida International University and also is an adjunct faculty and PhD guide at the MS University of Baroda. He is an adjunct faculty at MS University, visiting professor and PhD. guide at Charotar University Science and Technology Gujarat and Visiting Professor and PhD. guide at Swineburne University of Science and Technology, Melbourne, Australia.
        </p>
        <p className="mb-4">
Dr. Ramchand is a M. Phil and PhD in Biochemistry from MS University Baroda. He also served as assistant professor (1982-84) at MS University.
        </p>
      </div>
    </div>
  );
};

export default AboutCNR;
