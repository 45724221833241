import React from "react";
import Frontiers from "../../assets/logos/publications/Frontiers.jpg";
import Animal_Biotechnology from "../../assets/logos/publications/Animal_Biotechnology.jpg";
import communicationBiology from "../../assets/logos/publications/Communicaion_Biology.jpg";

const Publication2019 = () => {
  return (
    <div className="publications2019 mt-5">
      <div className="wrapper bg-white rounded-md p-5">
        <h1 className=" pb-4 mb-4 border-b text-lg">
          Massively parallel single-cell B-cell receptor sequencing enables
          rapid discovery of diverse antigen-reactive antibodies.
        </h1>
        <div className="flex justify-between items-center gap-4">
          <div className="left w-2/12">
            <img src={communicationBiology} alt="" />
          </div>
          <div className="right w-10/12">
            <p className=" text-base font-normal mb-4">
              Leonard D. Goldstein, Ying-Jiun J. Chen, Jia Wu, Subhra Chaudhuri,
              Yi-Chun Hsiao, Kellen Schneider, Kam Hon Hoi, Zhonghua Lin, Steve
              Guerrero, Bijay S. Jaiswal, Jeremy Stinson, Aju Antony, Kanika
              Bajaj Pahuja, Dhaya Seshasayee, Zora Modrusan, Isidro Hötzel &
              Somasekar Seshagiri
            </p>
            <a
              className=" text-blue-500"
              href="https://dx.doi.org/10.1038%2Fs42003-019-0551-y"
              target="_blank"
            >
              DOI:10.1038/s41598-018-27084-y
            </a>
          </div>
        </div>
      </div>
      <div className="wrapper bg-white rounded-md p-5 mt-5">
        <h1 className=" pb-4 mb-4 border-b text-lg">
          Whole Genome Sequencing and Comparative Genomic Analysis Reveal
          Allelic Variations Unique to a Purple Colored Rice Landrace (Oryza
          sativa ssp. indica cv. Purpleputtu)
        </h1>
        <div className="flex justify-between items-center gap-4">
          <div className="left w-2/12">
            <img src={Frontiers} alt="" />
          </div>
          <div className="right w-10/12">
            <p className=" text-base font-normal mb-4">
              V. B. Reddy Lachagari1, Ravi Gupta, Sivarama Prasad Lekkala,
              Lakshmi Mahadevan, Boney Kuriakose, Navajeet Chakravartty, A. V.
              S. K. Mohan Katta, Sam Santhosh, Arjula R. Reddy and George Thomas
            </p>
            <a
              className=" text-blue-500"
              href="https://dx.doi.org/10.3389%2Ffpls.2019.00513"
              target="_blank"
            >
              DOI:10.3389/fpls.2019.00513
            </a>
          </div>
        </div>
      </div>
      <div className="wrapper bg-white rounded-md p-5 mt-5">
        <h1 className=" pb-4 mb-4 border-b text-lg">
          Complete mitogenome reveals genetic divergence and phylogenetic
          relationships among Indian cattle (Bos indicus) breeds
        </h1>
        <div className="flex justify-between items-center gap-4">
          <div className="left w-2/12">
            <img src={Animal_Biotechnology} alt="" />
          </div>
          <div className="right w-10/12">
            <p className=" text-base font-normal mb-4">
              R. Kumar Pramod, Dinesh Velayutham, Sajesh P. K., Beena P. S.,
              Anil Zachariah,Arun Zachariah, Chandramohan B., Sujith S. S.,
              Ganapathi P., Bangarusamy Dhinoth Kumar, Sosamma Iype,Ravi Gupta,
              Sam Santhosh & George Thomas
            </p>
            <a
              className=" text-blue-500"
              href="https://doi.org/10.1080/10495398.2018.1476376"
              target="_blank"
            >
              DOI:10.1080/10495398.2018.1476376
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Publication2019;
