import React from "react";
import venoumous from "../../assets/logos/publications/venoumous.jpg";
import cancer_cell from "../../assets/logos/publications/cancer-cell.jpg";
import plos from "../../assets/logos/publications/plos.jpg";
import plos1 from "../../assets/logos/publications/plos-one.jpg";
import Scientific_reports from "../../assets/logos/publications/Scientific-reports.jpg";

const Publication2018 = () => {
  return (
    <div className="publications2018 mt-5">
      <div className="wrapper bg-white rounded-md p-5">
        <h1 className=" pb-4 mb-4 border-b text-lg">
          Next-generation sequencing analysis reveals high bacterial diversity
          in wild venomous and non-venomous snakes from India
        </h1>
        <div className="flex justify-between items-center gap-4">
          <div className="left w-2/12">
            <img src={venoumous} alt="" />
          </div>
          <div className="right w-10/12">
            <p className=" text-base font-normal mb-4">
              Sajesh Puthenpurackal Krishnankutty, Megha Muraleedharan,
              Rajadurai Chinnasamy Perumal, Saju Michael, Jubina Benny, Bipin
              Balan, Pramod Kumar, Jishnu Manazhi, Bangaruswamy Dhinoth Kumar,
              Sam Santhosh, George Thomas, Ravi Gupta & Arun Zachariah
            </p>
            <a
              className=" text-blue-500"
              href="https://dx.doi.org/10.1186%2Fs40409-018-0181-8"
              target="_blank"
            >
              DOI:10.1186/s40409-018-0181-8
            </a>
          </div>
        </div>
      </div>
      <div className="wrapper bg-white rounded-md p-5 mt-5">
        <h1 className=" pb-4 mb-4 border-b text-lg">
          Reinvestigating the status of malaria parasite (Plasmodium sp.) in
          Indian non-human primates
        </h1>
        <div className="flex justify-between items-center gap-4">
          <div className="left w-2/12">
            <img src={plos} alt="" />
          </div>
          <div className="right w-10/12">
            <p className=" text-base font-normal mb-4">
              Jyotsana Dixit ,Arun Zachariah,Sajesh P. K.,Bathrachalam
              Chandramohan,Vinoth Shanmuganatham,K. Praveen Karanth
            </p>
            <a
              className=" text-blue-500"
              href="https://dx.doi.org/10.1371%2Fjournal.pntd.0006801"
              target="_blank"
            >
              DOI:10.1371/journal.pntd.0006801
            </a>
          </div>
        </div>
      </div>
      <div className="wrapper bg-white rounded-md p-5 mt-5">
        <h1 className=" pb-4 mb-4 border-b text-lg">
          Actionable Activating Oncogenic ERBB2/HER2 Transmembrane and
          Juxtamembrane Domain Mutations
        </h1>
        <div className="flex justify-between items-center gap-4">
          <div className="left w-2/12">
            <img src={cancer_cell} alt="" />
          </div>
          <div className="right w-10/12">
            <p className=" text-base font-normal mb-4">
              Kanika Bajaj Pahuja, Thong T. Nguyen, Bijay S. Jaiswal, Kumar
              Prabhash, Tarjani M. Thaker, Kate Senger, Subhra Chaudhuri, Noelyn
              M. Kljavin, Aju Antony, Sameer Phalke, Prasanna Kumar, Marco
              Mravic, Eric W. Stawiski, Derek Vargas, Steffen Durinck, Ravi
              Gupta, Arati Khanna-Gupta, Sally E. Trabucco, Ethan S. Sokol, Ryan
              J. Hartmaier, Ashish Singh, Anuradha Chougule, Vaishakhi Trivedi,
              Amit Dutt, Vijay Patil, Amit Joshi, Vanita Noronha, James Ziai,
              Sripad D. Banavali, Vedam Ramprasad, William F. DeGrado, Raphael
              Bueno, Natalia Jura, Somasekar Seshagiri
            </p>
            <a
              className=" text-blue-500"
              href="https://dx.doi.org/10.1016%2Fj.ccell.2018.09.010"
              target="_blank"
            >
              DOI:10.1016/j.ccell.2018.09.010
            </a>
          </div>
        </div>
      </div>
      <div className="wrapper bg-white rounded-md p-5 mt-5">
        <h1 className=" pb-4 mb-4 border-b text-lg">
          Extended genotypic evaluation and comparison of twenty-two cases of
          lethal EEHV1 hemorrhagic disease in wild and captive Asian elephants
          in India
        </h1>
        <div className="flex justify-between items-center gap-4">
          <div className="left w-2/12">
            <img src={plos1} alt="" />
          </div>
          <div className="right w-10/12">
            <p className=" text-base font-normal mb-4">
              A. Zachariah,P. K. Sajesh,S. Santhosh,C. Bathrachalam,M. Megha,J.
              Pandiyan,M. Jishnu,R. S. Kobragade,S. Y. Long,J-C Zong,E. M.
              Latimer,S. Y. Heaggans,G. S. Hayward
            </p>
            <a
              className=" text-blue-500"
              href="https://dx.doi.org/10.1371%2Fjournal.pone.0202438"
              target="_blank"
            >
              DOI:10.1371/journal.pone.0202438
            </a>
          </div>
        </div>
      </div>
      <div className="wrapper bg-white rounded-md p-5 mt-5">
        <h1 className=" pb-4 mb-4 border-b text-lg">
          Comprehensive analysis of single molecule sequencing-derived complete
          genome and whole transcriptome of Hyposidra talaca nuclear
          polyhedrosis virus
        </h1>
        <div className="flex justify-between items-center gap-4">
          <div className="left w-2/12">
            <img src={Scientific_reports} alt="" />
          </div>
          <div className="right w-10/12">
            <p className=" text-base font-normal mb-4">
              Thong T. Nguyen, Kushal Suryamohan, Boney Kuriakose, Vasantharajan
              Janakiraman, Mike Reichelt, Subhra Chaudhuri, Joseph Guillory,
              Neethu Divakaran, P. E. Rabins, Ridhi Goel, Bhabesh Deka, Suman
              Sarkar, Preety Ekka, Yu-Chih Tsai, Derek Vargas, Sam Santhosh,
              Sangeetha Mohan, Chen-Shan Chin, Jonas Korlach, George Thomas,
              Azariah Babu & Somasekar Seshagiri
            </p>
            <a
              className=" text-blue-500"
              href="https://dx.doi.org/10.1038%2Fs41598-018-27084-y"
              target="_blank"
            >
              DOI:10.1038/s41598-018-27084-y
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Publication2018;
