import { Alert, Button, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import sentMail from "../../axios/sentMail";
import flagIndia from "../../assets/images/flag_india.png";
import flagUsa from "../../assets/images/flag_usa.png";
import ReCAPTCHA from "react-google-recaptcha";

const ContactForm = () => {
  const [mailSending, setMailSending] = useState(false);
  const [mailData, setMailData] = useState(null);
  const [mailResponse, setMailResponse] = useState("");
  const [formValid, setFormValid] = useState(true);
  const [captchaErr, setCaptchaErr] = useState(true);

  const nameRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  const messageRef = useRef();
  const recaptchaRef = useRef();

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText("#fff"),
    backgroundColor: "#0D2D51",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#d47100c7",
    },
  }));
  const validateForm = (data) => {
    let status = true;
    !data.name ? (status = false) : (status = true);
    !data.email ? (status = false) : (status = true);
    !data.phone ? (status = false) : (status = true);
    !data.message ? (status = false) : (status = true);
    setFormValid(status);
    return status;
  };
  const contactFormSubmitHandler = (e) => {
    e.preventDefault();
    const captchaValue = recaptchaRef.current.getValue();
    if (captchaValue === "") {
      setCaptchaErr(false);
      return;
    }
    const formData = {
      name: nameRef.current.value,
      email: emailRef.current.value,
      phone: phoneRef.current.value,
      message: messageRef.current.value,
      recaptchaValue: recaptchaRef.current.getValue(),
    };
    const formStat = validateForm(formData);
    if (!formStat || !captchaErr) return;
    setMailData(formData);
    nameRef.current.value = "";
    emailRef.current.value = "";
    phoneRef.current.value = "";
    messageRef.current.value = "";
    recaptchaRef.current.reset();
  };
  useEffect(() => {
    const mailsent = async () => {
      setMailSending(true);
      const response = await sentMail(mailData);
      setMailResponse(response);
      setMailSending(false);
    };
    if (!mailData) {
      return;
    }
    mailsent();
  }, [mailData]);
  useEffect(() => {
    const timout = setTimeout(() => {
      setMailResponse("");
    }, 3000);
    return () => {
      clearTimeout(timout);
    };
  }, [mailResponse]);
  useEffect(() => {
    const timout = setTimeout(() => {
      setFormValid(true);
    }, 3000);
    return () => {
      clearTimeout(timout);
    };
  }, [formValid]);
  useEffect(() => {
    const timout = setTimeout(() => {
      setCaptchaErr(true);
    }, 3000);
    return () => {
      clearTimeout(timout);
    };
  }, [captchaErr]);
  return (
    <>
      <div className="contact-containern min-h-screen px-4 md:px-7 lg:px-32 relative pt-28 mt-3 md:mt-5">
        <motion.div initial={{ x: "-10vw" }} animate={{ x: 0 }}>
          <h1 className=" text-3xl text-white mb-3">Contact Us</h1>
        </motion.div>
        <div className=" flex gap-12 flex-col md:flex-row justify-between">
          <motion.div
            transition={{ delay: 0.5 }}
            initial={{ y: "10vw", opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            className=" w-full sm:w-8/12 md:w-6/12 lg:w-5/12 bg-transparent rounded-md"
          >
            <form action="" method="post" onSubmit={contactFormSubmitHandler}>
              <div className="inputWrapper flex flex-wrap flex-col lg:flex-row justify-start gap-3">
                <div className="input w-full">
                  <input
                    className="w-full border border-[#01eff791] outline-none rounded-md p-2 bg-[#0D2D51] text-[#ffffffbe]"
                    type="text"
                    name="name"
                    id=""
                    placeholder="Name"
                    ref={nameRef}
                  />
                </div>
                <div className="input w-full">
                  <input
                    className="w-full border border-[#01eff791] outline-none rounded-md p-2 bg-[#0D2D51] text-[#ffffffbe]"
                    type="email"
                    name="email"
                    id=""
                    placeholder="Email"
                    ref={emailRef}
                  />
                </div>
                <div className="input w-full">
                  <input
                    className="w-full border border-[#01eff791] outline-none rounded-md p-2 bg-[#0D2D51] text-[#ffffffbe]"
                    type="number"
                    name="phone"
                    id=""
                    placeholder="Phone"
                    ref={phoneRef}
                  />
                </div>
                <div className="input w-full">
                  <textarea
                    className="w-full border border-[#01eff791] outline-none rounded-md p-2 bg-[#0D2D51] text-[#ffffffbe]"
                    name="message"
                    id=""
                    placeholder="Message"
                    ref={messageRef}
                  ></textarea>
                </div>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                />
                <div className="input w-full">
                  {!mailSending && (
                    <ColorButton
                      type="submit"
                      className=" w-3/12"
                      variant="contained"
                    >
                      Sent
                    </ColorButton>
                  )}
                  {mailSending && (
                    <div className=" flex justify-start gap-2 items-center mt-2">
                      <CircularProgress sx={{ color: "#fff" }} />
                      <span style={{ color: "#fff" }}>Sending Mail...</span>
                    </div>
                  )}
                </div>
              </div>
            </form>
            {mailResponse && (
              <motion.div
                initial={{ x: "-10vw", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                className=" w-5/12 p-2"
              >
                <Alert severity="success">{mailResponse}</Alert>
              </motion.div>
            )}
            {!formValid && (
              <motion.div
                initial={{ x: "-10vw", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                className=" w-full p-2"
              >
                <Alert severity="error">
                  Please Fill all details and try again!
                </Alert>
              </motion.div>
            )}
            {!captchaErr && (
              <motion.div
                initial={{ x: "-10vw", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                className=" w-full p-2"
              >
                <Alert severity="error">Please verify you are a human!</Alert>
              </motion.div>
            )}
          </motion.div>
          <motion.div
            initial={{ x: "20vw" }}
            animate={{ x: 0 }}
            className="w-full border-slate-300 sm:w-8/12 md:w-6/12 lg:w-5/12 bg-transparent rounded-md"
          >
            <div className="flex">
              <div className="left w-6/12">
                <div className="flag">
                  <img src={flagIndia} alt="" />
                </div>
                <div className="content py-3 text-white font-normal font-sans">
                  <h3 className=" text-white font-medium text-2xl">INDIA</h3>
                  <p className=" text-xs mb-3">
                    Plot no: 43A, SDF, 3rd floor A Block, CSEZ,
                  </p>
                  <p className=" text-xs mb-6">
                    Kakkanad, Cochin, Kerala - 682037
                  </p>
                  <p className=" text-xs">Phone: +91 484 - 2413399</p>
                </div>
              </div>
              <div className="right w-6/12">
                <div className="flag px-5">
                  <img src={flagUsa} alt="" />
                </div>
                <div className="content py-3 text-white border-l px-5 border-[#01eff771]">
                  <h3 className=" text-white font-medium text-2xl">
                    UNITED STATES
                  </h3>
                  <p className=" text-xs mb-3">
                    315 Montgomery Street, Suite 900
                  </p>
                  <p className=" text-xs mb-6">San Francisco, CA 94104</p>
                  <p className=" text-xs">Phone: +1 925 218 2207</p>
                </div>
              </div>
            </div>
            <div className="fax text-white border p-2 rounded-md border-[#01eff791] mt-2">
              <h3 className=" text-center text-xs">Fax: +91 484 - 2413398</h3>
            </div>
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default ContactForm;
