import React from "react";
import Chemical_research from "../../assets/logos/publications/Chemical-research-in-toxicology.jpg";
import schizophrenia from "../../assets/logos/publications/schizophrenia-research.jpg";
import Analytical_biochemistry from "../../assets/logos/publications/Analytical-biochemistry.jpg";
import Ijsem from "../../assets/logos/publications/Ijsem.jpg";
import Bioinformatics_research from "../../assets/logos/publications/Bioinformatics-research.jpg";
import Genome_anouncement from "../../assets/logos/publications/Genome-anouncement.jpg";

const Publication2017 = () => {
  return (
    <div className="publications2017 mt-5">
      <div className="wrapper bg-white rounded-md p-5">
        <h1 className=" pb-4 mb-4 border-b text-lg">
          Transcriptomic Analysis of Thalidomide Challenged Chick Embryo
          Suggests Possible Link between Impaired Vasculogenesis and Defective
          Organogenesis
        </h1>
        <div className="flex justify-between items-center gap-4">
          <div className="left w-2/12">
            <img src={Chemical_research} alt="" />
          </div>
          <div className="right w-10/12">
            <p className=" text-base font-normal mb-4">
              Vimal Veeriah, Pavitra Kumar, Lakshmikirupa Sundaresan, Zeenath
              Mafitha, Ravi Gupta, Uttara Saran, Jeganathan Manivannan, Suvro
              Chatterjee
            </p>
            <a
              className=" text-blue-500"
              href="https://doi.org/10.1021/acs.chemrestox.7b00199"
              target="_blank"
            >
              DOI:10.1021/acs.chemrestox.7b00199
            </a>
          </div>
        </div>
      </div>
      <div className="wrapper bg-white rounded-md p-5 mt-5">
        <h1 className=" pb-4 mb-4 border-b text-lg">
          Possible role of rare variants in Trace amine associated receptor 1 in
          schizophrenia
        </h1>
        <div className="flex justify-between items-center gap-4">
          <div className="left w-2/12">
            <img src={schizophrenia} alt="" />
          </div>
          <div className="right w-10/12">
            <p className=" text-base font-normal mb-4">
              JibinJohn, Prachi Kukshal, Triptish Bhatiab, K.V.Chowdari,
              V.L.Nimgaonkar, S.N.Deshpande, B.K.Thelma
            </p>
            <a
              className=" text-blue-500"
              href="https://doi.org/10.1016/j.schres.2017.02.020"
              target="_blank"
            >
              DOI:10.1016/j.schres.2017.02.020
            </a>
          </div>
        </div>
      </div>
      <div className="wrapper bg-white rounded-md p-5 mt-5">
        <h1 className=" pb-4 mb-4 border-b text-lg">
          A novel method for immobilization of proteins via entrapment of
          magnetic nanoparticles through epoxy cross-linking
        </h1>
        <div className="flex justify-between items-center gap-4">
          <div className="left w-2/12">
            <img src={Analytical_biochemistry} alt="" />
          </div>
          <div className="right w-10/12">
            <p className=" text-base font-normal mb-4">
              Tessy Iype, Jaiby Thomas, Sangeetha Mohan, Kochurani K.Johnson,
              Ligi E.GeorgebLizebona A, Ambattu, Aniruddh Bhati, Kristen
              Ailsworth, Bindu Menon, Sunayana M.Rayabandla, Rachel A.
              Jesudasan, SamSanthosh, Chaniyilparampu N.Ramchand
            </p>
            <a
              className=" text-blue-500"
              href="https://doi.org/10.1016/j.ab.2016.12.007"
              target="_blank"
            >
              DOI:10.1016/j.ab.2016.12.007
            </a>
          </div>
        </div>
      </div>
      <div className="wrapper bg-white rounded-md p-5 mt-5">
        <h1 className=" pb-4 mb-4 border-b text-lg">
          Halodesulfovibrio spirochaetisodalis gen. nov. sp. nov. and
          reclassification of four Desulfovibrio spp
        </h1>
        <div className="flex justify-between items-center gap-4">
          <div className="left w-2/12">
            <img src={Ijsem} alt="" />
          </div>
          <div className="right w-10/12">
            <p className=" text-base font-normal mb-4">
              Y. Shivani​, Y. Subhash​, Ch. Sasikala1, Ch. V. Ramana
            </p>
            <a
              className=" text-blue-500"
              href="https://doi.org/10.1099/ijsem.0.001574"
              target="_blank"
            >
              DOI:10.1099/ijsem.0.001574
            </a>
          </div>
        </div>
      </div>
      <div className="wrapper bg-white rounded-md p-5 mt-5">
        <h1 className=" pb-4 mb-4 border-b text-lg">
          Annotation transfer by homology among closely related genomes helps to
          identify protein function in Plasmodium species
        </h1>
        <div className="flex justify-between items-center gap-4">
          <div className="left w-2/12">
            <img src={Bioinformatics_research} alt="" />
          </div>
          <div className="right w-10/12">
            <p className=" text-base font-normal mb-4">
              Devaraj Raghul Rajan, Bathrachalam Chandramohan
            </p>
            <a
              className=" text-blue-500"
              href="https://dx.doi.org/10.1504/IJBRA.2017.082054"
              target="_blank"
            >
              DOI:10.1504/IJBRA.2017.082054
            </a>
          </div>
        </div>
      </div>
      <div className="wrapper bg-white rounded-md p-5 mt-5">
        <h1 className=" pb-4 mb-4 border-b text-lg">
          Draft Genome Sequence ofKlebsiellapneumoniaeOK8, a
          Multidrug-ResistantMouse and Human Pathogen
        </h1>
        <div className="flex justify-between items-center gap-4">
          <div className="left w-2/12">
            <img src={Genome_anouncement} alt="" />
          </div>
          <div className="right w-10/12">
            <p className=" text-base font-normal mb-4">
              Easa Nagamalleswari, Valakunja Nagaraja
            </p>
            <a
              className=" text-blue-500"
              href="https://mra.asm.org/content/5/37/e01018-17.short"
              target="_blank"
            >
              DOI:10.1128/genomeA.01018-17
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Publication2017;
