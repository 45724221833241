import React, { useState } from "react";
import sam_santhosh from "../../assets/images/sam.png";
import george_thomas from "../../assets/images/george.png";
import balamaniamma from "../../assets/images/balamani_amma.png";
import sangeetha from "../../assets/images/sangeetha.png";
import samish_karunalayam from "../../assets/images/samish_karunalayam.png";
import amit_chaudhuri from "../../assets/images/amit_chaudhuri.png";
import AboutGeorgeThomas from "../management-team/AboutGeorgeThomas";
import AboutSamSanthosh from "../management-team/AboutSamSanthosh";
import AboutBalamaniAmma from "../management-team/AboutBalamaniAmma";
import AboutSangeetha from "../management-team/AboutSangeetha";
import AboutAmitChaudhuri from "../management-team/AboutAmitChaudhuri";
import AboutSamishKarunalayam from "../management-team/AboutSamishKarunalayam";
import { motion } from "framer-motion";


const ManagementTeam = ({ activeTopBar, setActiveTopBar }) => {
  return (
    <>
      <motion.div
        initial={{ y: "10vw", opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.4 }}
        className="management"
      >
        <h1 className=" text-white text-xl sm:text-2xl md:text-3xl lg:text-4xl mb-5 text-center">
          Management Team
        </h1>
        <div className="managementWraper flex flex-wrap p-1 justify-center">
          <div className="managementTeamBox w-full md:w-9/12 p-1 flex justify-center">
            <div className="managementTeamContent p-1 w-full flex flex-col items-center">
              <img
                onClick={(e) => setActiveTopBar(e.target.dataset.name)}
                data-name="sam"
                className=" mb-4 cursor-pointer"
                src={sam_santhosh}
                alt=""
              />
              <h1 className="text-2xl">Sam Santhosh</h1>
              <h3 className="text-sm">Promoter & CEO</h3>
            </div>
          </div>
{/*
          <div className="managementTeamBox w-full md:w-4/12 p-1 flex justify-center mt-8">
            <div className="managementTeamContent p-1 w-full flex flex-col items-center">
              <img
                onClick={(e) => setActiveTopBar(e.target.dataset.name)}
                data-name="george"
                className=" mb-4 cursor-pointer"
                src={george_thomas}
                alt=""
              />
              <h1 className="text-2xl">George Thomas</h1>
              <h3 className="text-sm">Director R & D</h3>
            </div>
          </div>
*/}
          <div className="managementTeamBox w-full md:w-4/12 p-1 flex justify-center mt-8">
            <div className="managementTeamContent p-1 w-full flex flex-col items-center">
              <img
                onClick={(e) => setActiveTopBar(e.target.dataset.name)}
                data-name="amit_chaudhuri"
                className=" mb-4 cursor-pointer"
                src={amit_chaudhuri}
                alt=""
              />
              <h1 className="text-2xl">Dr. Amit Chaudhuri</h1>
              <h3 className="text-sm">Head of R&D</h3>
            </div>
          </div>
          <div className="managementTeamBox w-full md:w-4/12 p-1 flex justify-center mt-8">
            <div className="managementTeamContent p-1 w-full flex flex-col items-center">
              <img
                onClick={(e) => setActiveTopBar(e.target.dataset.name)}
                data-name="balamaniamma"
                className=" mb-4 cursor-pointer"
                src={balamaniamma}
                alt=""
              />
              <h1 className="text-2xl">Balamani Amma</h1>
              <h3 className="text-sm">Board Member</h3>
            </div>
          </div>
          <div className="managementTeamBox w-full md:w-4/12 p-1 flex justify-center mt-8">
            <div className="managementTeamContent p-1 w-full flex flex-col items-center">
              <img
                onClick={(e) => setActiveTopBar(e.target.dataset.name)}
                data-name="samish_karunalayam"
                className=" mb-4 cursor-pointer"
                src={samish_karunalayam}
                alt=""
              />
              <h1 className="text-2xl">Samish Karunalayam</h1>
              <h3 className="text-sm">Financial Controller</h3>
            </div>
          </div>
{/*
          <div className="managementTeamBox w-full md:w-4/12 p-1 flex justify-center mt-8">
            <div className="managementTeamContent p-1 w-full flex flex-col items-center">
              <img
                onClick={(e) => setActiveTopBar(e.target.dataset.name)}
                data-name="sangeetha"
                className=" mb-4 cursor-pointer"
                src={sangeetha}
                alt=""
              />
              <h1 className="text-2xl">Dr. Sangeetha</h1>
              <h3 className="text-sm">Principal Investigator</h3>
            </div>
          </div>
*/}
        </div>
      </motion.div>
      <div
        onClick={(e) =>
          Array.from(e.target.classList).includes("active")
            ? setActiveTopBar("")
            : null
        }
        className={` overlay ${activeTopBar === "sam" ? "active" : ""}`}
      >
        <AboutSamSanthosh
          activeTopBar={activeTopBar}
          setActiveTopBar={setActiveTopBar}
        />
      </div>
      <div
        onClick={(e) =>
          Array.from(e.target.classList).includes("active")
            ? setActiveTopBar("")
            : null
        }
        className={` overlay ${activeTopBar === "amit_chaudhuri" ? "active" : ""}`}
      >
        <AboutAmitChaudhuri
          activeTopBar={activeTopBar}
          setActiveTopBar={setActiveTopBar}
        />
      </div>
      <div
        onClick={(e) =>
          Array.from(e.target.classList).includes("active")
            ? setActiveTopBar("")
            : null
        }
        className={` overlay ${
          activeTopBar === "balamaniamma" ? "active" : ""
        }`}
      >
        <AboutBalamaniAmma
          activeTopBar={activeTopBar}
          setActiveTopBar={setActiveTopBar}
        />
      </div>
      <div
        onClick={(e) =>
          Array.from(e.target.classList).includes("active")
            ? setActiveTopBar("")
            : null
        }
        className={` overlay ${activeTopBar === "samish_karunalayam" ? "active" : ""}`}
      >
        <AboutSamishKarunalayam
          activeTopBar={activeTopBar}
          setActiveTopBar={setActiveTopBar}
        />
      </div>
    </>
  );
};

export default ManagementTeam;
