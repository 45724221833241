import React from "react";
import CloseIcon from "@mui/icons-material/Close";

const AboutBalamaniAmma = ({ activeTopBar, setActiveTopBar }) => {
  return (
    <div className="bg-[#fff] rounded-tl-xl rounded-tr-xl rounded-br-xl rounded-bl-xl text-black absolute w-6/12 z-10 topBar">
      <div className="header flex justify-between mb-4 bg-[#061D3A] text-white items-center px-8 py-3 rounded-tl-xl rounded-tr-xl">
        <h1 className=" text-2xl m-0">Balamani Amma</h1>
        <div
          className="close cursor-pointer"
          onClick={() => setActiveTopBar("")}
        >
          <CloseIcon />
        </div>
      </div>
      <div className="content readmore px-8 py-5">
        <p className=" mb-3">
          <strong>Balamani Amma</strong> is currently a board member of
          MedGenome Labs, Bangalore, SciGenom Labs, Cochin, AgriGenome Labs,
          Hyderabad, and MagGenome Technologies.
        </p>
        <p className=" mb-3">
          She has 28 years of rich experience in teaching and has been part of
          the management team of SciGenom group of laboratories since 2008. She
          is active in many social outreach efforts in Education and Healthcare.
        </p>
      </div>
    </div>
  );
};

export default AboutBalamaniAmma;
