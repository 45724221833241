import React from "react";
import scigenomTransition from "../../assets/images/Scigenom_transition.png";
import { ArrowRight } from "@mui/icons-material";

const ScigenomSection = () => {
  return (
    <section
      className="observeClass scigenome min-h-screen bg-white"
      id="scigenome"
    >
      <div className="topSection min-h-[80vh] flex flex-wrap">
        <div className="left w-full md:w-6/12 flex justify-center items-center pt-32 md:pt-0">
          <div className="contentWrapper w-full pl-4 md:pl-7 lg:pl-32 text-white">
            <div className="title mb-16">
              <h1 className=" text-4xl mb-2">Sam’s Second Innings!</h1>
              <p className=" text-lg font-medium">From software to genomics</p>
            </div>
            <div className="company mb-8">
              <h1 className=" text-3xl font-semibold mb-2">SciGenom</h1>
              <p className="text-sm font-medium">
                Genomics Incubator Kochi, India
              </p>
            </div>
            <div className="linkButton mb-4 md:mb-0">
              <a
                href="http://www.scigenom.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className=" px-3 py-2 border rounded-full">
                  www.scigenom.com
                </button>
              </a>
            </div>
          </div>
        </div>
        <div className="right w-full md:w-6/12 flex justify-center items-center">
          <div className="contentWrapper w-full pl-3 md:pl-5 lg:pl-10 text-white pt-16">
            <div className="title mb-2">
              <h1 className=" text-3xl font-semibold">Genomics Incubator</h1>
            </div>
            <div className="vision mb-2">
              <h1 className=" text-lg">Vision:</h1>
              <p className=" font-medium text-sm">
                Leverage Next Generation Sequencing Technologies to create
                companies in various verticals.
              </p>
            </div>
            <div className="vision mb-2">
              <h1 className=" text-lg">Strategy:</h1>
              <p className="font-medium text-sm">
                Set up core lab | Incubate different units | Recruit management
                team for each | Establish business model | Spin them off.
              </p>
            </div>
            <div className="list">
              <ul>
                <li className=" text-sm">
                  <ArrowRight fontSize="small" />
                  Incubated and spun out 4 companies
                </li>
                <li className=" text-sm">
                  <ArrowRight fontSize="small" /> 10,000 sq.ft lab in Special
                  Economic Zone, Kochi
                </li>
                <li className=" text-sm">
                  <ArrowRight fontSize="small" /> R&D lab recognized by
                  Department of Scientific & Industrial Research (DSIR), India
                </li>
                <li className=" text-sm">
                  <ArrowRight fontSize="small" /> Founded the non-profit
                  SciGenom Research Foundation (SGRF)
                </li>
              </ul>
            </div>
            <div className="portfolio-sprite flex">
              <div className="img flex items-center flex-col">
                <div className="founded mb-2"></div>
                <div className="text text-center text-sm">
                  <span className=" font-medium">Founded</span> <br />
                  2009
                </div>
              </div>
              <div className="img flex items-center flex-col">
                <div className="location mb-2"></div>
                <div className="text text-center text-sm">
                  <span className=" font-medium">Location</span> <br />
                  Kochi, India
                </div>
              </div>
              <div className="img flex items-center flex-col">
                <div className="people mb-2"></div>
                <div className="text text-center text-sm">
                  <span className=" font-medium">People</span> <br />
                  15
                </div>
              </div>
              <div className="img flex items-center flex-col">
                <div className="ledby mb-2"></div>
                <div className="text text-center text-sm">
                  <span className=" font-medium">Led By</span> <br />
                  Sam Santhosh
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottomSection min-h-[20vh] flex justify-center bg-[#001835]">
        <div className="years px-4 md:px-7 lg:px-32 py-5 overflow-x-auto">
          <img src={scigenomTransition} alt="" />
        </div>
      </div>
    </section>
  );
};

export default ScigenomSection;
