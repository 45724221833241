import React, { useEffect } from "react";
import AggenomeSection from "../../components/portfolio-overview/AggenomeSection";
import MaggenomeSection from "../../components/portfolio-overview/MaggenomeSection";
import MedgenomeSection from "../../components/portfolio-overview/MedgenomeSection";
import SaskinSection from "../../components/portfolio-overview/SaskinSection";
import ScigenomSection from "../../components/portfolio-overview/ScigenomSection";
import SgrfSection from "../../components/portfolio-overview/SgrfSection";
import Footer from "../../components/footer/Footer";
import "./portfolio.css";

const ObserveElement = ({ elementClass, addClass }) => {
  const observeElement = document.querySelectorAll(elementClass);
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add(addClass);
          observer.unobserve(entry.target);
        }
      });
    },
    {
      root: null,
      rootMargin: "0px",
      threshold: 0,
    }
  );
  observeElement.forEach((element) => {
    observer.observe(element);
  });
};

const PortfolioOverview = ({ setShowIso }) => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
    const observer = {
      elementClass: ".observeClass",
      addClass: "show",
    };
    ObserveElement(observer);
  }, []);
  return (
    <div className="portfolio-wrapper">
      <ScigenomSection />
      <div className=" w-full h-2 sci-med-divider mx-auto"></div>
      <MedgenomeSection />
      <div className=" w-full h-2 med-sas-divider mx-auto"></div>
      <SaskinSection />
      <div className=" w-full h-2 sas-agg-divider mx-auto"></div>
      <AggenomeSection />
      <div className=" w-full h-2 agg-mag-divider mx-auto"></div>
      <MaggenomeSection />
      <div className=" w-full h-2 magg-sgrf-divider mx-auto"></div>
      <SgrfSection />
      <Footer setShowIso={setShowIso} />
    </div>
  );
};

export default PortfolioOverview;
