import React from "react";
import { motion } from "framer-motion";

const CompanyOverview = ({ setActiveSection, setActiveTopBar }) => {
  const changeActiveSection = () => {
    setActiveSection("management");
    setActiveTopBar("sam");
  };
  return (
    <motion.div
      initial={{ x: "-20vw", opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      className="companyOverview text-slate-300 text-xs sm:text-base md:text-base lg:text-lg"
    >
      <h1 className=" text-white text-xl sm:text-2xl md:text-3xl lg:text-4xl mb-5">
        About Us
      </h1>
      <p className=" text-base sm:text-base md:text-base">
        SciGenom was founded in 2009 by{" "}
        <span className=" cursor-pointer" onClick={changeActiveSection}>
          Sam Santhosh
        </span>{" "}
        with the vision to leverage the science of the Genome. Sam believed that
        understanding the structure of the DNA and deciphering (or sequencing)
        the genetic code was the most significant achievement of our species
        till date. Coming from a non-biology background he was amazed to learn
        that all life forms in Earth share the same code and this code
        constituted the ‘language of life’. With his background in IT and system
        level programming, Sam was confident that this power of this language
        would enable us to solve many of the problems facing us. Especially for
        a country like India with a large population, major healthcare problems,
        abject poverty, inadequate food and water, Genomics could be a great
        tool to make a significant impact. At the same time, successful
        companies could also be created while addressing these problems.
      </p>
      <p className="text-base sm:text-base md:text-base mt-5">
        With this vision in mind, Sam envisaged SciGenom as a Genomics incubator
        with DNA Sequencing & Bioinformatics as a ‘Horizontal Technology’ which
        could solve problems in different verticals like Healthcare, Agriculture
        and Drug Discovery. The first step was to create a lab with sequencing
        and bioinformatics skills and such a facility was set up in Kochi, India
        in Jan 2010. A core team was recruited by inviting professionals in U.S
        to move back to India and simultaneously training local resources from
        India in the labs of collaborators in U.S and Canada.
      </p>
      <p className="text-base sm:text-base md:text-base mt-5">
        Starting with Sanger and 454 sequencing machines, SciGenom quickly
        established leadership in India offering sequencing services and
        bioinformatics analysis to R&D institutions across India.{" "}
        <a
          href="https://bmcgenomics.biomedcentral.com/articles/10.1186/1471-2164-13-440"
          target="_blank"
          rel="noopener noreferrer"
          className="text-base sm:text-base md:text-base seq-publishing text-white"
        >
          Sequencing and publishing
        </a>
        &nbsp; the first whole genome of an Indian female and many other
        scientific publications got SciGenom the scientific recognition in
        India. The launch of the non-profit SciGenom Research Foundation in 2011
        and the first NGBT conference in Dec 2011 laid the foundation for
        SciGenom’s research efforts. As Next Generation Sequencing (NGS)
        Platforms were brought out by Illumina and PacBio, SciGenom was the
        first in India to bring thee to India to both provide the best services
        to local institiuions and also develop solutions in various verticals.
      </p>
      <p className="text-base sm:text-base md:text-base mt-5">
        Clinical Diagnostics seemed to be most obvious area where DNA sequencing
        could add value and hence MedGenome was incubated as a division in
        SciGenom in 2011. This was then spun off in 2013 as an independent
        company based in Bangalore and San Francisco. Subsequently AgriGenome,
        Saksin Life Sciences and MagGenome were incubated and spun out. Details
        of these companies can be seen in the Portfolio section.
      </p>
      <p className="text-base sm:text-base md:text-base mt-5">
        Now SciGenom continues to incubate more companies leveraging new
        technologies in Genome Editing, Hi-Plex Proteomics, Nanotechnology and
        Robotics. With a DSIR certified lab in Kochi and the support of its
        portfolio companies and the investor community, SciGenom hopes to
        continue to help address India’s problems
      </p>
    </motion.div>
  );
};

export default CompanyOverview;
