import React from "react";
import { motion } from "framer-motion";
import grouth from "../../assets/images/growth_history.png";

const GrouthHistory = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ type: "tween", delay: 0.5 }}
      className="companyOverview text-slate-300 text-xs sm:text-base md:text-base lg:text-lg"
    >
      <h1 className=" text-white text-xl sm:text-2xl md:text-3xl lg:text-4xl mb-5">
        Growth History
      </h1>
      <div className="">
        <img className="w-full md:w-[80%]" src={grouth} alt="" />
      </div>
    </motion.div>
  );
};

export default GrouthHistory;
