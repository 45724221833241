import React from "react";
import "./banner2.css";
import dna from "../../assets/logos/dnaStruct.png";

const banner2 = () => {
  return (
    <div className="banner bg-[#061D3A] min-h-screen h-full flex justify-center items-center px-4 md:px-7 lg:px-32 relative">
      <div className="banner_inner w-full flex flex-wrap flex-col md:flex-row">
        <div className="banner_left w-full md:w-6/12 mb-12 md:mb-0 flex items-center relative min-h-[50vh]">
          <img
            src={dna}
            alt=""
            className="rotateSlow absolute top-0 right-10 max-w-full h-full"
            style={{ zIndex: -1 }}
          />
          <h1 className=" text-white text-5xl ">SciGenom Labs</h1>
        </div>
        <div className="banner_right w-full md:w-6/12 py-5 px-2 rounded-md flex items-center">
          <div>
            <div className="content-top text-white text-sm mb-5 border-l-4 pl-3 border-[#9d0a99d6]">
              <h3 className=" w-10/12">
                Our first initiative was to Leverage Next Generation Sequencing (NGS)
                Technologies to create companies in various verticals
              </h3>
            </div>
            <div className="content-bottom text-white text-sm border-l-4 pl-3 border-[#9d0a99d6]">
              <h3 className=" w-10/12">
                The incubator was established 2009. It has incubated and spun
                off successful companies in areas such as Diagnostics,
                Agriculture, Drug discovery and Nano-technology leveraging NGS
              </h3>
            </div>
          </div>
        </div>
        <div className="banner-bottom w-full mt-10 flex flex-col md:flex-row justify-center md:justify-between text-white">
          <div className="bottom_content explore-grad w-full mb-5 md:mb-0 md:w-3/12 pl-5 py-3">
            <h4 className="bottom_content_title uppercase font-medium text-3xl tracking-widest mb-2">
              explore
            </h4>
            <p className="bottom_content_text text-xs">
              Learn all about the basics of entrepreneurship with our explore
              programs and become part of the new generation of Bio-tech
              entrepreneurs.
            </p>
          </div>
          <div className="bottom_content biolab-bg w-full mb-5 md:mb-0 md:w-3/12 pl-5 py-3">
            <h4 className="bottom_content_title uppercase font-medium text-3xl tracking-widest mb-2">
              bio lab
            </h4>
            <p className="bottom_content_text text-xs">
              Engage in corporate – startup collaboration to solve real world
              challenges and generate a Bio-Tech based spin-off
            </p>
          </div>
          <div className="bottom_content incubation-bg w-full mb-5 md:mb-0 md:w-3/12 pl-5 py-3">
            <h4 className="bottom_content_title uppercase font-medium text-3xl tracking-widest mb-2">
              incubation
            </h4>
            <p className="bottom_content_text text-xs">
              Transform your tech or science-based ideas into an impactful
              company of the future with our incubation program
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default banner2;
