import React from "react";
import Scientific_reports from "../../assets/logos/publications/Scientific-reports.jpg";
import mabs from "../../assets/logos/publications/mabs.jpg";
import nature from "../../assets/logos/publications/nature.jpg";

const Publication2020 = () => {
  return (
    <div className="publications2020 mt-5">
      <div className="wrapper bg-white rounded-md p-5">
        <h1 className=" pb-4 mb-4 border-b text-lg">
          Phenotypic expression and clinical outcomes in a South Asian PRKAG2
          cardiomyopathy cohort.
        </h1>
        <div className="flex justify-between items-center gap-4">
          <div className="left w-2/12">
            <img src={Scientific_reports} alt="" />
          </div>
          <div className="right w-10/12">
            <p className=" text-base font-normal mb-4">
              Hisham Ahamed, Aniketh Vijay Balegadde, Shilpa Menon, Ramesh
              Menon, Aishwarya Ramachandran, Navin Mathew, K U Natarajan, Indu
              Ramachandran Nair, Rajesh Kannan, Meghna Shankar, Oommen K Mathew,
              Thong T Nguyen, Ravi Gupta, Eric W Stawiski, V L Ramprasad,
              Somasekar Seshagiri, Sameer Phalke
            </p>
            <a
              className=" text-blue-500"
              href="https://www.nature.com/articles/s41598-020-77124-9"
              target="_blank"
            >
              DOI:10.1038/s41598-020-77124-9
            </a>
          </div>
        </div>
      </div>
      <div className="wrapper bg-white rounded-md p-5 mt-5">
        <h1 className=" pb-4 mb-4 border-b text-lg">
          Restricted epitope specificity determined by variable region germline
          segment pairing in rodent antibody repertoires.
        </h1>
        <div className="flex justify-between items-center gap-4">
          <div className="left w-2/12">
            <img src={mabs} alt="" />
          </div>
          <div className="right w-10/12">
            <p className=" text-base font-normal mb-4">
              Yi-Chun Hsiao, Ying-Jiun J. Chen, Leonard D. Goldstein, Jia Wu,
              Zhonghua Lin, Kellen Schneider, Subhra Chaudhuri, Aju Antony,
              Kanika Bajaj Pahuja, Zora Modrusan, Dhaya Seshasayee, Somasekar
              Seshagiri & Isidro Hötzel
            </p>
            <a
              className=" text-blue-500"
              href="https://doi.org/10.1080/19420862.2020.1722541"
              target="_blank"
            >
              DOI:10.1080/19420862.2020.1722541
            </a>
          </div>
        </div>
      </div>
      <div className="wrapper bg-white rounded-md p-5 mt-5">
        <h1 className=" pb-4 mb-4 border-b text-lg">
          The Indian cobra reference genome and transcriptome enables
          comprehensive identification of venom toxins.
        </h1>
        <div className="flex justify-between items-center gap-4">
          <div className="left w-2/12">
            <img src={nature} alt="" />
          </div>
          <div className="right w-10/12">
            <p className=" text-base font-normal mb-4">
              Kushal Suryamohan, Sajesh P. Krishnankutty, Joseph Guillory,
              Matthew Jevit, Markus S. Schröder, Meng Wu, Boney Kuriakose,
              Oommen K. Mathew, Rajadurai C. Perumal, Ivan Koludarov, Leonard D.
              Goldstein, Kate Senger, Mandumpala Davis Dixon, Dinesh Velayutham,
              Derek Vargas, Subhra Chaudhuri, Megha Muraleedharan, Ridhi Goel,
              Ying-Jiun J. Chen, Aakrosh Ratan, Peter Liu, Brendan Faherty,
              Guillermo de la Rosa, Hiroki Shibata, Miriam Baca, Meredith
              Sagolla, James Ziai, Gus A. Wright, Domagoj Vucic, Sangeetha
              Mohan, Aju Antony, Jeremy Stinson, Donald S. Kirkpatrick, Rami N.
              Hannoush, Steffen Durinck, Zora Modrusan, Eric W. Stawiski,
              Kristen Wiley, Terje Raudsepp, R. Manjunatha Kini, Arun Zachariah
              & Somasekar Seshagiri
            </p>
            <a
              className=" text-blue-500"
              href="https://doi.org/10.1038/s41588-019-0559-8"
              target="_blank"
            >
              DOI:10.1038/s41588-019-0559-8
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Publication2020;
