import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Footer from "../../components/footer/Footer";
import ListIcon from "@mui/icons-material/List";
import "./publications.css";
import PublicationSection from "../../components/publication-components/PublicationSection";
import CollaborationSection from "../../components/publication-components/CollaborationSection";
import ResearchGrantsSection from "../../components/publication-components/ResearchGrantsSection";

const Publications = ({ setShowIso }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeSection, setActiveSection] = useState("publications");

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className="aboutWrapper pt-24 h-screen">
        <div className="flex px-4 md:px-7 lg:px-32 mt-6">
          <div className="left w-1/12 md:w-3/12 pl-2">
            <div className="aboutusLinks">
              <ul>
                <li>
                  <div
                    onClick={() => setActiveSection("publications")}
                    className={`aboutUsSecTitle ${
                      activeSection === "publications" ? "active" : ""
                    } text-sm hidden md:block`}
                  >
                    Publications
                  </div>
                </li>
                <li>
                  <div
                    onClick={() => setActiveSection("collabrations")}
                    className={`aboutUsSecTitle ${
                      activeSection === "collabrations" ? "active" : ""
                    } text-sm hidden md:block`}
                  >
                    Collabrations
                  </div>
                </li>
                <li>
                  <div
                    onClick={() => setActiveSection("grants")}
                    className={`aboutUsSecTitle ${
                      activeSection === "grants" ? "active" : ""
                    } text-sm hidden md:block`}
                  >
                    Research Grants
                  </div>
                </li>
                <li>
                  <span
                    className=" text-white cursor-pointer md:hidden"
                    onClick={handleClick}
                  >
                    <ListIcon fontSize="large" />
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="right w-11/12 md:w-9/12  pl-10">
            {activeSection === "publications" && <PublicationSection />}
            {activeSection === "collabrations" && <CollaborationSection />}
            {activeSection === "grants" && <ResearchGrantsSection />}
          </div>
        </div>
        <div className="mobileMenu">
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                setActiveSection("publications");
                handleClose();
              }}
            >
              Publications
            </MenuItem>
            <MenuItem
              onClick={() => {
                setActiveSection("collabrations");
                handleClose();
              }}
            >
              Collabrations
            </MenuItem>
            <MenuItem
              onClick={() => {
                setActiveSection("grants");
                handleClose();
              }}
            >
              Research Grants
            </MenuItem>
          </Menu>
        </div>
      </div>
      <Footer setShowIso={setShowIso} />
    </>
  );
};

export default Publications;
