import React from "react";
import "./banner5.css";
import dna from "../../assets/logos/dnaStruct.png";
import Footer from "../../components/footer/Footer";
import { Link } from "react-router-dom";

const Banner5 = () => {
  return (
    <>
      <div className="banner bg-[#061D3A] min-h-screen h-full flex justify-center items-center px-4 md:px-7 lg:px-32 relative">
        <img
          src={dna}
          alt=""
          className=" absolute top-0 right-10 max-w-full h-[50%] md:h-[80%]"
          style={{ zIndex: -1 }}
        />
        <div className="banner_inner w-full flex flex-wrap flex-col md:flex-row">
          <div className="banner_left w-full md:w-6/12 mb-12 md:mb-0 flex flex-col items-start relative min-h-[50vh]">
            <h1 className=" text-white text-5xl w-8/12 mb-10">
              Explore & Join Our Ecosystem
            </h1>
            <p className=" w-10/12 text-white exploreText">
              Become a part of the greater SciGenom community through our
              virtual and in-person network opportunities
            </p>
          </div>
          <div className="banner-bottom w-full mt-10 flex flex-col md:flex-row justify-center md:justify-between text-white">
            <Link to="/career" className=" w-full md:w-3/12">
              <div className="bottom_content explore-grad w-full mb-5 md:mb-0 pl-5 py-3">
                <h4 className="bottom_content_title capitalize font-medium text-3xl tracking-widest mb-2">
                  Join Us
                </h4>
                <p className="bottom_content_text text-xs">
                  5 portfolio companies, SciGenom startups constantly have
                  openings for top talent who want to take their careers to the
                  next level
                </p>
              </div>
            </Link>
            <div className="bottom_content biolab-bg w-full mb-5 md:mb-0 md:w-3/12 pl-5 py-3">
              <h4 className="bottom_content_title capitalize font-medium text-3xl tracking-widest mb-2">
                Events
              </h4>
              <p className="bottom_content_text text-xs">
                India’s Founder Fireside Chats. Join thought-provoking speakers
                sharing their visions of tomorrow.
              </p>
            </div>
            <Link to="/news" className=" w-full md:w-3/12">
              <div className="bottom_content incubation-bg w-full mb-5 md:mb-0 pl-5 py-3">
                <h4 className="bottom_content_title capitalize font-medium text-3xl tracking-widest mb-2">
                  In Media
                </h4>
                <p className="bottom_content_text text-xs">
                  Our startups make headlines. Get the latest news on companies
                  making a difference in human and planetary health.
                </p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner5;
