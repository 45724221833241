const CareerData = [
  {
    id: 1,
    title: "Principal scientist reporting to CSO/CEO",
    responsibilities: [
      "Recruit, organize and manage a core R&D team in SciGenom for the next level of growth",
      "Provide members of the R&D team with continuous scientific input in different areas of product development and train them to follow best laboratory practices",
      "Ensure that the final products of the companies being incubated meet quality standards and customer needs",
      "Responsible for maintaining high quality documentation for products developed in-house, including method validation reports, product development reports and standard operating procedures",
      "Identify areas of unmet need and suggest new product development opportunities to the management",
      "Support the Product & Strategy Group in SciGenom for building a viable product portfolio based on the latest developments in areas relevant for the company",
      "Support marketing and sales group with content development for websites, whitepaper and other marketing documents",
      "Bring external research funding either as independent or collaborative research projects",
      "Explore and assess R&D partnership opportunities with collaborators, including pharmaceutical companies, biotechnology companies, academic institutions, and not-for-profit organizations",
      "Represent the company at scientific meetings and conferences to present the ongoing research",
      "Preparation and submission of original research for publication in high quality journals",
      "Submission of patent applications to protect inventions",
      "Oversee purchase, and allocation of resources for the R&D team working with the CSO/CEO",
    ],
    background:
      "SciGenom Labs is a Biotech incubator established in Kochi, Kerala in 2009 by Sam Santhosh. It has incubated and spun off successful companies such as MedGenome, AgriGenome, Saksin Life sciences and MagGenome. It has also set up a non-profit Trust called SciGenom Research Foundation (SGRF) that works to promote Science in India through research and education. SciGenom is now creating a core R&D team in Kochi to incubate a new set of companies leveraging the latest scientific discoveries and cutting-edge technologies",
    required_skills: [
      "Deep knowledge in one or more of the areas listed: Molecular Biology /Biochemistry/Immunology/Protein Chemistry/Nano-biotechnology",
      "Ability to lead and motivate a team for optimum performance",
      "Organized to coordinate, prioritize, multi-task, and work well under pressure to meet deadlines",
      "Strong analytical skills and judgment and problem-solving competencies",
      "Excellent communication skills",
      "Strong technical writing skills (procedures, protocols, manuscripts and reports",
    ],
    who_you_are: [
      "Ph.D. in biochemistry/molecular biology/protein chemistry/immunology/biotechnology or chemistry/ organic chemistry with basic understanding of biology",
      "Minimum 15 years’ relevant experience post Ph.D. in academia OR industry (preferred) in a scientific role",
      "Hands on experience in developing new assays/products",
      "Experience is working with proteins and proteomic technologies (optional)",
      "Proven ability to build, manage and lead a team successfully",
      "Proven scientific competency through peer reviewed publications",
    ],
    location: {
      city: "Kochi",
      state: "Kerala",
      country: "India",
    },
  },
  {
    id: 2,
    title:
      "Scientific/Sr. Scientific Researcher reporting to Scientist/Principal Scientist",
    responsibilities: [
      "Establish and implement assays under the guidance of the Scientist/Principal Scientist ",
      "Work to establish methodologies and assays required for product qualification and validation",
      "Help Scientist/Principal Scientist with product documentation, validation report and certificate of quality for market release",
      "Participate in other scientific activities as required by the manager",
    ],
    background:
      "SciGenom Labs is a Biotech incubator established in Kochi, Kerala in 2009 by Sam Santhosh. It has incubated and spun off successful companies such as MedGenome, AgriGenome, Saksin Life sciences and MagGenome. It has also set up a non-profit Trust called SciGenom Research Foundation (SGRF) that works to promote Science in India through research and education. SciGenom is now creating a core R&D team in Kochi to incubate a new set of companies leveraging the latest scientific discoveries and cutting-edge technologies.",
    required_skills: [
      "Experience working with proteins including purification and characterization, protein immunoprecipitation and detection by western blotting and ELISA assays",
      "Experience with liquid chromatography instruments such as HPLC and FPLC desirable",
      "Basic molecular biology and cell culture techniques required",
      "Good communication and writing skills",
    ],
    who_you_are: [
      "MS in biological sciences/chemistry with 4+ years of laboratory experience in academia or industry (preferred)",
      "Team player, motivated and detail oriented",
    ],
    location: {
      city: "Kochi",
      state: "Kerala",
      country: "India",
    },
  },
  {
    id: 3,
    title: "Scientist reporting to Principal Scientist",
    responsibilities: [
      "Develop and implement innovative products for the life sciences market using MNPs and protein-based assays",
      "Work with the Principal Scientist to identify key areas of product development",
      "Establish methodologies and assays required for product qualification and validation",
      "Create product documentation, validation report and certificate of quality for market release",
      "Work closely with the sales team, give product demo, resolve customer queries and interface with customers as and when required",
      "Manage one or two RAs’, and be responsible for their training and day-to-day activities",
    ],
    background:
      "SciGenom Labs is a Biotech incubator established in Kochi, Kerala in 2009 by Sam Santhosh. It has incubated and spun off successful companies such as MedGenome, AgriGenome, Saksin Life sciences and MagGenome. It has also set up a non-profit Trust called SciGenom Research Foundation (SGRF) that works to promote Science in India through research and education. SciGenom is now creating a core R&D team in Kochi to incubate a new set of companies leveraging the latest scientific discoveries and cutting-edge technologies.",
    required_skills: [
      "Demonstrated ability of having developed and/or optimized biochemical and cell-based assays related to specific projects",
      "Demonstrated ability in protein purification and characterization by analytical chromatography, protein immunoprecipitation and detection by western blotting",
      "Experience in mass spectrometry desirable but not essential",
      "Basic molecular biology and cell culture techniques required",
      "Publications demonstrating knowledge and expertise in area of specialization",
      "Experience managing research associates desirable but not required",
      "Good communication and writing skills",
    ],
    who_you_are: [
      "PhD in biological science with 4+ years of laboratory experience in academia or industry (preferred)",
      "Team player, motivated and detail oriented",
      "Knowledge seeking and a passion for developing and working with new technologies",
      "Demonstrated ability to present in scientific conferences and internal meetings",
    ],
    location: {
      city: "Kochi",
      state: "Kerala",
      country: "India",
    },
  },
];

export default CareerData;
