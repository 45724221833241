import React from "react";
import ContactForm from "../../containers/contactform/ContactForm";
import Footer from "../../components/footer/Footer";

const Contact = ({ setShowIso }) => {
  return (
    <>
      <ContactForm />
      <Footer setShowIso={setShowIso} />
    </>
  );
};

export default Contact;
