import React from "react";
import CloseIcon from "@mui/icons-material/Close";

const AboutSamishKarunalayam = ({ activeTopBar, setActiveTopBar }) => {
  return (
    <div className="bg-[#fff] rounded-tl-xl rounded-tr-xl rounded-br-xl rounded-bl-xl text-black absolute w-6/12 z-10 topBar">
      <div className="header flex justify-between mb-4 bg-[#061D3A] text-white items-center px-8 py-3 rounded-tl-xl rounded-tr-xl">
        <h1 className=" text-2xl m-0">Samish Karunalayam</h1>
        <div
          className="close cursor-pointer"
          onClick={() => setActiveTopBar("")}
        >
          <CloseIcon />
        </div>
      </div>
      <div className="content readmore px-8 py-5">
        <p className=" mb-3">
          Samish Karunalayam is our Financial Controller and oversees
          SciGenom’s and its related managed entities
          Financial Planning & Analysis, Auditing, Accounting & Controllership,
          Treasury management and Stakeholder management.
          He is a Chartered Accountant with over 17 years of experience
          predominantly in IT Industry.
        </p>
        <p className=" mb-3">
          Samish has joined us from Aspire Systems Digital erstwhile RapidValue,
          where he was Head of Finance, contributing actively across various
          functions of the Group. He brings with him a wealth of experience in
          analytical strategic planning, business acumen & reliability in
          financial reporting and ensuring diligence, governance, and controls
          in operations. He was instrumental in the takeover of digital
          transformation business by the Aspire Group from erstwhile RapidValue.
        </p>
        <p className=" mb-3">
          In his earlier role at Sagitec Solutions, US he worked in the
          managerial position playing a significant role on migrating from
          legacy systems of the group to Oracle NetSuite. Prior to that he was
          with Ernst and Young where he worked in
          Audit, Assurance and Advisory roles.
        </p>
      </div>
    </div>
  );
};

export default AboutSamishKarunalayam;
