import React from "react";
import Saskin_transition from "../../assets/images/Saskin_transition.png";
import saskinlogo from "../../assets/logos/portfolio/saskin_logo.png";
import { ArrowRight } from "@mui/icons-material";

const SaskinSection = () => {
  return (
    <section className="observeClass saskin min-h-screen bg-white" id="saskin">
      <div className="topSection min-h-[80vh] flex flex-wrap">
        <div className="left w-full md:w-6/12 flex justify-center items-center pt-32 md:pt-0">
          <div className="contentWrapper w-full pl-4 md:pl-7 lg:pl-32 text-white">
            <div className="logo mb-16">
              <img src={saskinlogo} alt="" />
            </div>
            <div className="content mb-8">
              <p className=" text-base font-medium w-8/12">
                Developing better biologic drugs to cure adult blindness caused
                by wet AMD at 1/5th the current cost
              </p>
            </div>
            <div className=" company mb-10">
              <h1 className=" text-3xl font-semibold mb-2">
                Saksin Life Sciences
              </h1>
              <p className=" text-sm">
                Developing Biobetters for the Indian market
              </p>
            </div>
            <div className="linkButton mb-4 md:mb-0">
              <a
                href="https://www.saksinlife.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className=" px-3 py-2 border rounded-full">
                  www.saksinlife.com
                </button>
              </a>
            </div>
          </div>
        </div>
        <div className="right w-full md:w-6/12 flex justify-center items-center">
          <div className="contentWrapper w-full pl-3 md:pl-5 lg:pl-10 text-white pt-16">
            <div className="vision mb-2">
              <h1 className=" text-lg">Vision:</h1>
              <ul>
                <li className=" text-sm">
                  <ArrowRight fontSize="small" />
                  Help Indian pharma move from Biosimilars to Biobetters
                </li>
              </ul>
            </div>
            <div className="vision mb-2">
              <h1 className=" text-lg">Strategy:</h1>
              <ul>
                <li className=" text-sm mb-3">
                  <ArrowRight fontSize="small" />
                  Develop a anti-VEGF biobetter for Lucentis to treat Age
                  related Macular Degeneration (AMD) in India
                </li>
                <li className=" text-sm">
                  <ArrowRight fontSize="small" />
                  Lab set up at TiCEL Biopark in Chenna
                </li>
                <li className=" text-sm">
                  <ArrowRight fontSize="small" />
                  End to end product development done in India
                </li>
                <li className=" text-sm">
                  <ArrowRight fontSize="small" />
                  One clone licensed to Theragen Life Sciences for diabetic
                  retinopathy
                </li>
              </ul>
            </div>

            <div className="portfolio-sprite flex">
              <div className="img flex items-center flex-col">
                <div className="founded mb-2"></div>
                <div className="text text-center text-sm">
                  <span className=" font-medium">Founded</span> <br />
                  2015
                </div>
              </div>
              <div className="img flex items-center flex-col">
                <div className="location mb-2"></div>
                <div className="text text-center text-sm">
                  <span className=" font-medium">Location</span> <br />
                  Chennai, India
                </div>
              </div>
              <div className="img flex items-center flex-col">
                <div className="people mb-2"></div>
                <div className="text text-center text-sm">
                  <span className=" font-medium">People</span> <br />
                  51
                </div>
              </div>
              <div className="img flex items-center flex-col">
                <div className="ledby mb-2"></div>
                <div className="text text-center text-sm">
                  <span className=" font-medium">Led By</span> <br />
                  Dr. Ramchand
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottomSection min-h-[20vh] flex justify-center bg-[#06243e]">
        <div className="years px-4 md:px-7 lg:px-32 py-5 overflow-x-auto">
          <img src={Saskin_transition} alt="" />
        </div>
      </div>
    </section>
  );
};

export default SaskinSection;
