import React from "react";
import medgenome from "../../assets/logos/medgenome.png";
import agrigenoem from "../../assets/logos/agrigenome.png";
import saksin from "../../assets/logos/saksin.png";
import maggenome from "../../assets/logos/maggenome.png";
import medgenomeLogo from "../../assets/logos/Asset 12.png";
import agrigenoemLogo from "../../assets/logos/Asset 13.png";
import saksinLogo from "../../assets/logos/Asset 15.png";
import maggenomeLogo from "../../assets/logos/Asset 14.png";
import { Link } from "react-router-dom";
import "./banner4.css";

const Banner4 = () => {
  return (
    <div
      className="banner gradient-bg4 min-h-screen h-full px-4 md:px-7 lg:px-32 pt-24 relative"
      id="portfolio"
    >
      <h1 className="text-2xl sm:text-4xl lg:text-5xl mb-6 md:mb-7 lg:mb-10 lg:leading-[3.7rem] text-white text-center">
        Meet the Portfolio
      </h1>

      <div className="companyInfo flex flex-wrap md:flex-row  justify-between gap-1 mt-20 text-white">
        <div className="compamyInfo w-[40%] md:w-[20%] min-h-[20vh] md:min-h-[32vh] relative overflow-hidden mb-5 md:mb-0">
          <Link to="/portfolio/#medgenome">
            <img src={medgenome} alt="" className=" w-full" />
          </Link>
          <div className="companyLogo absolute bottom-0 left-0 w-full bg-white h-20 rounded-tl-md rounded-tr-lg flex justify-center items-center">
            <Link to="/portfolio/#medgenome">
              <img src={medgenomeLogo} alt="" className="" />
            </Link>
          </div>
        </div>
        <div className="compamyInfo w-[40%] md:w-[20%] min-h-[20vh] md:min-h-[32vh] relative overflow-hidden mb-5 md:mb-0">
          <Link to="/portfolio/#aggenome">
            <img src={agrigenoem} alt="" className=" w-full" />
          </Link>
          <div className="companyLogo absolute bottom-0 left-0 w-full bg-white h-20 rounded-tl-md rounded-tr-lg flex justify-center items-center">
            <Link to="/portfolio/#aggenome">
              <img src={agrigenoemLogo} alt="" className=" h-[72px]" />
            </Link>
          </div>
        </div>
        <div className="compamyInfo w-[40%] md:w-[20%] min-h-[20vh] md:min-h-[32vh] relative overflow-hidden mb-5 md:mb-0">
          <Link to="/portfolio/#maggenome">
            <img src={maggenome} alt="" className=" w-full" />
          </Link>
          <div className="companyLogo absolute bottom-0 left-0 w-full bg-white h-20 rounded-tl-md rounded-tr-lg flex justify-center items-center">
            <Link to="/portfolio/#maggenome">
              <img src={maggenomeLogo} alt="" className="" />
            </Link>
          </div>
        </div>
        <div className="compamyInfo w-[40%] md:w-[20%] min-h-[20vh] md:min-h-[32vh] relative overflow-hidden mb-5 md:mb-0">
          <Link to="/portfolio/#saskin">
            <img src={saksin} alt="" className=" w-full" />
          </Link>
          <div className="companyLogo absolute bottom-0 left-0 w-full bg-white h-20 rounded-tl-md rounded-tr-lg flex justify-center items-center">
            <Link to="/portfolio/#saskin">
              <img src={saksinLogo} alt="" className="" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner4;
