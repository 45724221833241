import React from "react";
import CloseIcon from "@mui/icons-material/Close";

const AboutSamSanthosh = ({ activeTopBar, setActiveTopBar }) => {
  return (
    <div className="bg-[#fff] rounded-tl-xl rounded-tr-xl rounded-br-xl rounded-bl-xl text-black absolute w-6/12 z-10 topBar">
      <div className="header flex justify-between mb-4 bg-[#061D3A] text-white items-center px-8 py-3 rounded-tl-xl rounded-tr-xl">
        <h1 className=" text-2xl m-0">Sam Santhosh - Promoter & CEO</h1>
        <div
          className="close cursor-pointer"
          onClick={() => setActiveTopBar("")}
        >
          <CloseIcon />
        </div>
      </div>
      <div className="content readmore px-8 py-5">
        <p className="text-black mb-3">
          Sam Santhosh is an entrepreneur with over 25 years of experience in
          founding and running successful businesses. Sam is the founder and
          Chairman of SciGenom Labs which has incubated and spun off a number of
          successful companies such as MedGenome, AgriGenome, Saksin Life
          Sciences and MagGenome. He currently focuses on the activities of the
          SciGenom Research Foundation (SGRF), a non-profit organization that
          promotes science education and research in India. Prior to this Sam
          was the CEO and Managing Director of California Software Limited
          (Calsoft) from 1992-2012. He founded Calsoft in 1992 and under Sam’s
          vision Calsoft rapidly grew from a niche player in the Silicon Valley
          to a global player with over 1200 employees and presence in 8
          countries. Sam took Calsoft public and listed the company in the
          Indian Stock Exchanges. Sam holds a degree in Mechanical Engineering
          and an MBA from IIM Calcutta. Sam is a strong believer of continuous
          learning and has attended various programs in University of Berkeley,
          MIT and Singularity University.
        </p>
        <p>
          Sam shuttles between Foster City, California and Kochi India while
          pursuing his various interests in History, Archeology and Botanical
          Gardens.
        </p>
      </div>
    </div>
  );
};

export default AboutSamSanthosh;
