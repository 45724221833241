import React, { useState } from "react";
import Publication2021 from "../publications/Publication2021";
import Publication2020 from "../publications/Publication2020";
import Publication2019 from "../publications/Publication2019";
import Publication2018 from "../publications/Publication2018";
import Publication2017 from "../publications/Publication2017";
import Publication2016 from "../publications/Publication2016";
import Publication2015 from "../publications/Publication2015";

const PublicationSection = () => {
  const [activePublicationYear, setActivePublicationYear] = useState("2021");

  const changeActiveYear = (e) => {
    const parant = document
      .querySelector(".publicationYears")
      .getBoundingClientRect();
    const thisX = e.target.getBoundingClientRect();
    const indicator = document.querySelector(".indicator");
    indicator.style.left = `${Math.abs(parant["x"] - thisX["x"])}px`;
    indicator.style.width = `${thisX["width"]}px`;
    const year = e.target.dataset.year;
    setActivePublicationYear(year);
  };
  return (
    <>
      <div className="publicationYears flex relative overflow-hidden">
        <div
          onClick={changeActiveYear}
          data-year="2021"
          className={`relative year px-5 py-3 ${
            activePublicationYear === "2021" ? "active" : ""
          }`}
        >
          <div className="indicator absolute top-0 left-0 h-1 bg-[#f79420] w-full transition-all duration-500"></div>
          2021
        </div>
        <div
          onClick={changeActiveYear}
          data-year="2020"
          className={`year px-5 py-3 ${
            activePublicationYear === "2020" ? "active" : ""
          }`}
        >
          2020
        </div>
        <div
          onClick={changeActiveYear}
          data-year="2019"
          className={`year px-5 py-3 ${
            activePublicationYear === "2019" ? "active" : ""
          }`}
        >
          2019
        </div>
        <div
          onClick={changeActiveYear}
          data-year="2018"
          className={`year px-5 py-3 ${
            activePublicationYear === "2018" ? "active" : ""
          }`}
        >
          2018
        </div>
        <div
          onClick={changeActiveYear}
          data-year="2017"
          className={`year px-5 py-3 ${
            activePublicationYear === "2017" ? "active" : ""
          }`}
        >
          2017
        </div>
        <div
          onClick={changeActiveYear}
          data-year="2016"
          className={`year px-5 py-3 ${
            activePublicationYear === "2016" ? "active" : ""
          }`}
        >
          2016
        </div>
        <div
          onClick={changeActiveYear}
          data-year="2015"
          className={`year px-5 py-3 ${
            activePublicationYear === "2015" ? "active" : ""
          }`}
        >
          2015 & Earlier
        </div>
      </div>
      <div className="yearWiseContent max-h-[70vh] overflow-y-auto">
        {activePublicationYear === "2021" && <Publication2021 />}
        {activePublicationYear === "2020" && <Publication2020 />}
        {activePublicationYear === "2019" && <Publication2019 />}
        {activePublicationYear === "2018" && <Publication2018 />}
        {activePublicationYear === "2017" && <Publication2017 />}
        {activePublicationYear === "2016" && <Publication2016 />}
        {activePublicationYear === "2015" && <Publication2015 />}
      </div>
    </>
  );
};

export default PublicationSection;
