import React, { useEffect, useState } from "react";
import Home from "./pages/home/Home";
import About from "./pages/about/About";
import Contact from "./pages/contact/Contact";
import Career from "./pages/career/Career";
import Publications from "./pages/publications/Publications";
import { Routes, Route } from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import useWindowScroll from "./hooks/useWindowScoll";
import ScrollToTop from "./components/ui/ScrollToTop";
import { useLocation } from "react-router-dom";
import IsoCertificate from "./components/IsoCertificate";
import "./App.css";
import News from "./pages/news/News";
import PortfolioOverview from "./pages/portfolio-overview/PortfolioOverview";
const App = () => {
  const offsetY = useWindowScroll();
  const [activeNavMenu, setActiveNavMenu] = useState("home");
  const location = useLocation();
  const [showIso, setShowIso] = useState(false);

  useEffect(() => {
    const { hash, pathname } = location;
    if (pathname === "/") {
      setActiveNavMenu("home");
    } else {
      const Path = pathname.replace("/", "").replace("/", "");
      setActiveNavMenu(Path);
    }
    if (!hash) return;
    if (pathname === "/") {
      setActiveNavMenu("home");
    } else {
      const Path = pathname.replace("/", "").replace("/", "");
      setActiveNavMenu(Path);
    }
    const elementID = hash.replace("#", "");
    const element = document.getElementById(elementID);

    //custom scroll to
    const headerOffset = 0;
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition + (elementID === "sgrf" ? 0 : 250),
      behavior: "smooth",
    });
    //custom scroll to end
  }, [location]);

  return (
    <>
      {showIso && <IsoCertificate setShowIso={setShowIso} />}
      <Navbar
        activeNavMenu={activeNavMenu}
        setActiveNavMenu={setActiveNavMenu}
        scrollTop={offsetY}
      />
      <Routes>
        <Route path="/" element={<Home setShowIso={setShowIso} />}></Route>
        <Route
          path="/about"
          element={<About setShowIso={setShowIso} />}
        ></Route>
        <Route
          path="/contact"
          element={<Contact setShowIso={setShowIso} />}
        ></Route>
        <Route
          path="/career"
          element={<Career setShowIso={setShowIso} />}
        ></Route>
        <Route
          path="/publications"
          element={<Publications setShowIso={setShowIso} />}
        ></Route>
        <Route path="/news" element={<News setShowIso={setShowIso} />}></Route>
        <Route
          path="/portfolio"
          element={<PortfolioOverview setShowIso={setShowIso} />}
        ></Route>
      </Routes>
      {offsetY > 100 && <ScrollToTop />}
    </>
  );
};

export default App;
