import React from "react";
import Mag_transition from "../../assets/images/Mag_transition.png";
import maglogo from "../../assets/logos/portfolio/mag_logo.png";
import { ArrowRight } from "@mui/icons-material";

const MaggenomeSection = () => {
  return (
    <section
      className="observeClass maggenome min-h-screen bg-white"
      id="maggenome"
    >
      <div className="topSection min-h-[80vh] flex flex-wrap">
        <div className="left w-full md:w-6/12 flex justify-center items-center pt-32 md:pt-0">
          <div className="contentWrapper w-full pl-4 md:pl-7 lg:pl-32 text-white">
            <div className="logo mb-16">
              <img src={maglogo} alt="" />
            </div>
            <div className="content mb-8">
              <p className=" text-base font-medium w-8/12">
                Designated as an official start-up of India with 40+ products –
                one of the first in bio-nanotechnology
              </p>
            </div>
            <div className=" company mb-10">
              <h1 className=" text-3xl font-semibold mb-2">MagGenome</h1>
              <p className=" text-sm">
                India’s only company producing DNA & RNA extraction kits using
                magnetic nanoparticles
              </p>
            </div>
            <div className="linkButton mb-4 md:mb-0">
              <a
                href="http://www.maggenome.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className=" px-3 py-2 border rounded-full">
                  www.maggenome.com
                </button>
              </a>
            </div>
          </div>
        </div>
        <div className="right w-full md:w-6/12 flex justify-center items-center">
          <div className="contentWrapper w-full pl-3 md:pl-5 lg:pl-10 text-white pt-16">
            <div className="vision mb-2">
              <h1 className=" text-lg">Vision:</h1>
              <ul>
                <li className=" text-sm">
                  <ArrowRight fontSize="small" />
                  Use our patented magnetic nanoparticle technology to develop
                  products for the life sciences industry
                </li>
              </ul>
            </div>
            <div className="vision mb-2">
              <h1 className=" text-lg">Strategy:</h1>
              <ul>
                <li className=" text-sm">
                  <ArrowRight fontSize="small" />
                  Develop and market DNA & RNA extraction kits for manual
                  process
                </li>
                <li className=" text-sm mb-3">
                  <ArrowRight fontSize="small" />
                  Then adapt them for high end automated systems
                </li>
                <li className=" text-sm">
                  <ArrowRight fontSize="small" />
                  Created specialized kits for different source material –
                  blood, tissue, & saliva
                </li>
                <li className=" text-sm">
                  <ArrowRight fontSize="small" />
                  Customized kits for different plants
                </li>
                <li className=" text-sm">
                  <ArrowRight fontSize="small" />
                  ICMR approved viral RNA extraction kit for Covid-19
                </li>
              </ul>
            </div>

            <div className="portfolio-sprite flex">
              <div className="img flex items-center flex-col">
                <div className="founded mb-2"></div>
                <div className="text text-center text-sm">
                  <span className=" font-medium">Founded</span> <br />
                  2015
                </div>
              </div>
              <div className="img flex items-center flex-col">
                <div className="location mb-2"></div>
                <div className="text text-center text-sm">
                  <span className=" font-medium">Location</span> <br />
                  Chennai & Kochi
                </div>
              </div>
              <div className="img flex items-center flex-col">
                <div className="people mb-2"></div>
                <div className="text text-center text-sm">
                  <span className=" font-medium">People</span> <br />
                  25
                </div>
              </div>
              <div className="img flex items-center flex-col">
                <div className="ledby mb-2"></div>
                <div className="text text-center text-sm">
                  <span className=" font-medium">Led By</span> <br />
                  Dr. Ramchand
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottomSection min-h-[20vh] flex justify-center bg-[#040203]">
        <div className="years px-4 md:px-7 lg:px-32 py-5 overflow-x-auto">
          <img src={Mag_transition} alt="" />
        </div>
      </div>
    </section>
  );
};

export default MaggenomeSection;
