import React from "react";
import communicationBiology from "../../assets/logos/publications/Communicaion_Biology.jpg";
import plantcell from "../../assets/logos/publications/plantcell.jpg";

const Publication2021 = () => {
  return (
    <div className="publications2021 mt-5">
      <div className="wrapper bg-white rounded-md p-5">
        <h1 className=" pb-4 mb-4 border-b text-lg">
          Human ACE2 receptor polymorphisms and altered susceptibility to
          SARS-CoV-2.
        </h1>
        <div className="flex justify-between items-center gap-4">
          <div className="left w-2/12">
            <img src={communicationBiology} alt="" />
          </div>
          <div className="right w-10/12">
            <p className=" text-base font-normal mb-4">
              Kushal Suryamohan, Devan Diwanji, Eric W Stawiski, Ravi Gupta,
              Shane Miersch, Jiang Liu, Chao Chen, Ying-Ping Jiang, Frederic A
              Fellouse, J Fah Sathirapongsasuti, Patrick K Albers, Tanneeru
              Deepak, Reza Saberianfar, Aakrosh Ratan, Gavin Washburn, Monika
              Mis, Devi Santhosh, Sneha Somasekar, G H Hiranjith, Derek Vargas,
              Sangeetha Mohan, Sameer Phalke, Boney Kuriakose, Aju Antony, Mart
              Ustav Jr, Stephan C Schuster, Sachdev Sidhu, Jagath R Junutula,
              Natalia Jura, Somasekar Seshagiri
            </p>
            <a
              className=" text-blue-500"
              href="https://www.nature.com/articles/s42003-021-02030-3"
              target="_blank"
            >
              DOI:10.1038/s42003-021-02030-3
            </a>
          </div>
        </div>
      </div>
      <div className="wrapper bg-white rounded-md p-5 mt-5">
        <h1 className=" pb-4 mb-4 border-b text-lg">
          Targeted editing of tomato carotenoid isomerase reveals the role of 5'
          UTR region in gene expression regulation.
        </h1>
        <div className="flex justify-between items-center gap-4">
          <div className="left w-2/12">
            <img src={plantcell} alt="" />
          </div>
          <div className="right w-10/12">
            <p className=" text-base font-normal mb-4">
              K Lakshmi Jayaraj, Nitu Thulasidharan, Aju Antony, Moni John,
              Rehna Augustine, Navajeet Chakravartty, Smitha Sukumaran, M Uma
              Maheswari, Sweety Abraham, George Thomas, V B Reddy Lachagari,
              Somasekar Seshagiri, Subhash Narayanan, Boney Kuriakose
            </p>
            <a
              className=" text-blue-500"
              href="https://link.springer.com/article/10.1007/s00299-020-02659-0"
              target="_blank"
            >
              DOI:10.1007/s00299-020-02659-0
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Publication2021;
