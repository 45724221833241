import React from "react";

const CollaborationSection = () => {
  return (
    <div className="collaboration text-slate-300 text-xs sm:text-base md:text-base lg:text-lg max-h-[70vh] overflow-y-auto">
      <h1 className=" text-white text-xl sm:text-2xl md:text-3xl lg:text-4xl mb-5">
        Collaborations
      </h1>
      <h2 className="text-lg sm:text-xl md:text-2xl lg:text-3xl mb-5 bg-[#ffffff1a] px-1">
        Sidhu lab, University of Toronto, Canada
      </h2>
      <p className=" text-base sm:text-base md:text-base">
        Sidhu lab at the University of Toronto is focusing on Phage-display
        technology and combinatorial protein engineering.
      </p>
      <p className=" mt-5 mb-5">
        Our collaboration with this lab aims in using this Phage-display
        platform for developing synthetic reagent antibodies, that can be a
        valuable alternative for traditional monoclonal antibodies used widely
        in basic and clinical research.
      </p>
      <a
        href="http://sites.utoronto.ca/sidhulab/"
        target="_blank"
        rel="noopener noreferrer"
      >
        http://sites.utoronto.ca/sidhulab/
      </a>
      <h2 className=" mt-10 mb-4 text-lg sm:text-xl md:text-2xl lg:text-3xl bg-[#ffffff1a] px-1">
        ICAR - CPCRI (Central Plantation Crops Research Institute (CPCRI),
        Kasargod, Kerala
      </h2>
      <a
        href="http://www.cpcri.gov.in/"
        target="_blank"
        rel="noopener noreferrer"
      >
        http://www.cpcri.gov.in/
      </a>
      <h2 className=" mt-10 mb-4 text-lg sm:text-xl md:text-2xl lg:text-3xl bg-[#ffffff1a] px-1">
        IOB (Institute of Bioinformatics), Bangalore
      </h2>
      <a
        href="http://www.ibioinformatics.org/"
        target="_blank"
        rel="noopener noreferrer"
      >
        http://www.ibioinformatics.org/
      </a>
      <h2 className=" mt-10 mb-4 text-lg sm:text-xl md:text-2xl lg:text-3xl bg-[#ffffff1a] px-1">
        MDRF( Madras Diabetic Reserach Foundation), Chennai
      </h2>
      <a href="http://mdrf.in/" target="_blank" rel="noopener noreferrer">
        http://mdrf.in/
      </a>
    </div>
  );
};

export default CollaborationSection;
