import React from "react";
import "./banner1.css";
import healthWorldLogo from "../../assets/logos/et_hw.jpg";
import liveMintLogo from "../../assets/logos/livemint.jpg";
import outLookLogo from "../../assets/logos/outlook.jpg";
import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";

const Banner1 = () => {
  return (
    <div
      id="news"
      className="banner gradientbg min-h-screen h-full flex flex-col md:flex-row justify-center lg:justify-start items-center px-4 md:px-7 lg:px-32 relative"
    >
      <div className="banner_left w-full md:w-6/12 mb-12 md:mb-0">
        <h1 className="text-2xl sm:text-3xl lg:text-4xl mb-6 md:mb-7 lg:mb-10 lg:leading-[3.7rem] text-white">
          SciGenom is incubating India’s Revolution In Bio-tech
        </h1>
      </div>
      <div className="banner_right w-full md:w-6/12 bg-white py-5 px-5 rounded-tl-md rounded-bl-md">
        <div className="title text-black text-xl mb-5">News</div>
        <ul className=" text-black ">
          <li className="flex mb-5 gap-5">
            <div className="w-2/12 flex items-center">
              <img src={healthWorldLogo} alt="" />
            </div>
            <div className=" w-10/12 pl-2 border-l-2">
              <p className=" text-xs">
                Indian cobra genome and venom genes decoded In the January 2020
                issue of Nature Genetics, scientists from SciGenom Research
                Foundation (SGRF), India, AgriGenome Labs, India, MedGenome,
                India/USA.
              </p>
            </div>
          </li>
          <Divider />
          <li className="flex mb-5 gap-5 mt-3">
            <div className="w-2/12 flex items-center">
              <img src={liveMintLogo} alt="" />
            </div>
            <div className=" w-10/12 pl-2 border-l-2">
              <p className=" text-xs">
                The concept of unity in diversity best fits India, not only for
                its culture, society and religion, but also for its gene pool.
                Research has revealed that certain Thalasemmia, a blood
                disorder, and genetic markers are exclusive to Indian
                population.
              </p>
            </div>
          </li>
          <Divider />
          <li className="flex mb-5 gap-5 mt-3">
            <div className="w-2/12 flex items-center">
              <img src={outLookLogo} alt="" />
            </div>
            <div className=" w-10/12 pl-2 border-l-2">
              <p className=" text-xs">
                Asia has at least 10 ancestral lineages, whereas northern Europe
                has just one, says a study of global genetic comparison
                including genome sequence from 598 individuals belonging to 55
                ethnic groups from India.
              </p>
            </div>
          </li>
        </ul>
        <div className=" flex justify-end">
          <Link to="/news">
            <button className="hover:text-sky-700 rounded-md bg-transparent px-2 py-1 border-2 border-gray-500">
              Read More
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Banner1;
