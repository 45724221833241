import React from "react";
import virology from "../../assets/logos/publications/Journal-of-virology.jpg";
import omics from "../../assets/logos/publications/omics.jpg";
import reology from "../../assets/logos/publications/Bio-reology.jpg";
import Biochimie from "../../assets/logos/publications/Biochimie.jpg";
import Phytopathology from "../../assets/logos/publications/Phytopathology.jpg";

const Publication2016 = () => {
  return (
    <div className="publications2016 mt-5">
      <div className="wrapper bg-white rounded-md p-5">
        <h1 className=" pb-4 mb-4 border-b text-lg">
          Multiplexed next-generation sequencing and de novo assembly to obtain
          near full-length HIV-1 genome from plasma virus
        </h1>
        <div className="flex justify-between items-center gap-4">
          <div className="left w-2/12">
            <img src={virology} alt="" />
          </div>
          <div className="right w-10/12">
            <p className=" text-base font-normal mb-4">
              Shambhu G.Aralaguppe, Abu BakarSiddik, Ashokkumar Manickam, Anoop
              T.Ambikan, Milner M.Kumar, Sunjay JudeFernandes, Wondwossen
              Amogne, Dhinoth K.Bangaruswamy, Luke ElizabethHanna, Anders
              Sonnerborg, Ujjwal Neogi
            </p>
            <a
              className=" text-blue-500"
              href="https://doi.org/10.1016/j.jviromet.2016.07.010"
              target="_blank"
            >
              DOI:10.1016/j.jviromet.2016.07.010
            </a>
          </div>
        </div>
      </div>
      <div className="wrapper bg-white rounded-md p-5 mt-5">
        <h1 className=" pb-4 mb-4 border-b text-lg">
          Transcriptomic Analysis of Chloroquine-Sensitive and
          Chloroquine-Resistant Strains of Plasmodium falciparum: Toward Malaria
          Diagnostics and Therapeutics for Global Health
        </h1>
        <div className="flex justify-between items-center gap-4">
          <div className="left w-2/12">
            <img src={omics} alt="" />
          </div>
          <div className="right w-10/12">
            <p className=" text-base font-normal mb-4">
              Hiasindh Ashmi Antony, Vrushali Pathak, Subhash Chandra Parija,
              Kanjaksha Ghosh, and Amrita Bhattacherjee
            </p>
            <a
              className=" text-blue-500"
              href="https://doi.org/10.1089/omi.2016.0058"
              target="_blank"
            >
              DOI:doi.org/10.1089/omi.2016.0058
            </a>
          </div>
        </div>
      </div>
      <div className="wrapper bg-white rounded-md p-5 mt-5">
        <h1 className=" pb-4 mb-4 border-b text-lg">
          The expression dynamics of mechanosensitive genes in extra-embryonic
          vasculature after heart starts to beat in chick embryo
        </h1>
        <div className="flex justify-between items-center gap-4">
          <div className="left w-2/12">
            <img src={reology} alt="" />
          </div>
          <div className="right w-10/12">
            <p className=" text-base font-normal mb-4">
              Rajendran Saranya, Sundaresan Lakshmikirupa, Rajendran Krithika,
              Selvaraj Monica, Gupta Ravi, Chatterjee Suvro
            </p>
            <a
              className=" text-blue-500"
              href="https://content.iospress.com/articles/biorheology/bir15075"
              target="_blank"
            >
              DOI:10.3233/BIR-15075
            </a>
          </div>
        </div>
      </div>
      <div className="wrapper bg-white rounded-md p-5 mt-5">
        <h1 className=" pb-4 mb-4 border-b text-lg">
          Harvesting clues from genome wide transcriptome analysis for exploring
          thalidomide mediated anomalies in eye development of chick embryo:
          Nitric oxide rectifies the thalidomide mediated anomalies by swinging
          back the system to normal transcriptome pattern
        </h1>
        <div className="flex justify-between items-center gap-4">
          <div className="left w-2/12">
            <img src={Biochimie} alt="" />
          </div>
          <div className="right w-10/12">
            <p className=" text-base font-normal mb-4">
              Pavitra Kumar, Dharanibalan Kasiviswanathan, Lakshmikirupa
              Sundaresan, Priyadarshan Kathirvel, Vimal Veeriah, Priya Dutta,
              Kavitha Sankaranarayanan, Ravi Gupta, Suvro Chatterjee
            </p>
            <a
              className=" text-blue-500"
              href="https://doi.org/10.1016/j.biochi.2015.12.013"
              target="_blank"
            >
              DOI:10.1016/j.biochi.2015.12.013
            </a>
          </div>
        </div>
      </div>
      <div className="wrapper bg-white rounded-md p-5 mt-5">
        <h1 className=" pb-4 mb-4 border-b text-lg">
          Association of 16SrII-D Phytoplasma With Phyllody Disease in Sesame
          (Sesamum indicum) in Telangana From Southern India
        </h1>
        <div className="flex justify-between items-center gap-4">
          <div className="left w-2/12">
            <img src={Phytopathology} alt="" />
          </div>
          <div className="right w-10/12">
            <p className=" text-base font-normal mb-4">
              I. Pamei and R. Makandar
            </p>
            <a
              className=" text-blue-500"
              href="https://doi.org/10.1094/PDIS-11-15-1348-PDN"
              target="_blank"
            >
              DOI:10.1094/PDIS-11-15-1348-PDN
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Publication2016;
