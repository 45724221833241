import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import "./career.css";
import CareerDetails from "../../components/careerDetails/CareerDetails";
import CareerData from "../../Data/CareerData";
import Footer from "../../components/footer/Footer";

const Career = ({ setShowIso }) => {
  const [currentActive, setCurrentActive] = useState(null);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <>
      <div className="careerWrapper px-4 md:px-7 lg:px-32 pt-24 mb-16 min-h-screen">
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ delay: 0.5 }}
          className="career-title text-white mt-5 mb-16"
        >
          <h1 className=" text-center text-4xl">Join Our team!</h1>
          <p className="w-full mx-auto text-[#ffffffaf] font-normal p-2 text-center mt-1">
            We are a rapidly growing organization looking for talented and self
            driven individuals who are willing to go that 'extra mile' to make
            things happen! For us, at SciGenom the desire to succeed is immense.
            Winning is not about acing the race; it is about running the race
            with integrity and determination, to deliver on our promise.
            Passionate about science and compulsive in its search for knowledge
            SciGenom offers world class working environment, professional
            management and ample opportunities to learn, innovate and grow.
            Collaborative teamwork combined with an occasional bout of
            individual brilliance is what keeps us ahead of the pack!
            <br />
            Come and discover opportunities to grow together
          </p>
        </motion.div>
        {/* Note: The id prop passed to <CareerDetails/> should be grater than 0 and must be number */}
        {CareerData.map((data) => (
          <CareerDetails
            id={data.id}
            key={data.id}
            currentActive={currentActive}
            setCurrentActive={setCurrentActive}
            title={data.title}
            responsibility={data.responsibilities}
            background={data.background}
            required_skills={data.required_skills}
            who_you_are={data.who_you_are}
            location={data.location}
          />
        ))}
      </div>
      <Footer setShowIso={setShowIso} />
    </>
  );
};

export default Career;
