import { useRef, useEffect } from "react";
import "./banner.css";
import bannerImg from "../../assets/banner_dna.png";
// import dnaVideo from "../../assets/dnaVideo.mp4";
import { Link } from "react-router-dom";
import { dnaSvg } from "../../d3/dnaSvg";
import phdNotification from "../../assets/downloads/PhD_Programme_Notification.pdf";
import alstoniaLogo from "../../assets/logos/Alstonialogo.jpg";

const Banner = () => {
  const dnaElemRef = useRef();
  useEffect(() => {
    dnaSvg(dnaElemRef.current);
  }, []);
  return (
    <div className="banner min-h-screen h-full bg-[#000] flex justify-center items-center px-4 md:px-7 lg:px-32 relative overflow-hidden">
      {/* <video
        className="fixed top-0 left-0 object-cover h-screen w-full"
        style={{
          zIndex: -1,
        }}
        src={dnaVideo}
        autoPlay
        muted
        loop
      ></video> */}
      <div
        ref={dnaElemRef}
        className="dnaStruct absolute top-0 left-0 bg-[#010d2b] w-[100vw] h-screen overflow-hidden pt-32"
        style={{ zIndex: -1 }}
      ></div>
      <div className="banner-content mt-20 text-white w-full flex justify-between flex-wrap">
        <div className="banner-content-left py-2 text-white w-full md:w-full lg:w-6/12 my-auto">
          <h1 className="text-2xl sm:text-3xl lg:text-4xl mb-6 md:mb-7 lg:mb-10 lg:leading-[3.7rem]">
            Bridging the gap between science and entrepreneurship
          </h1>
          <p className="banner-title mb-10 lg:mb-14 text-sm sm:text-base lg:leading-7 w-9/12 font-extralight">
            Leveraging Science and Technology to help solve India’s problems and
            create successful businesses.
          </p>
        <Link to={phdNotification} target="_blank">
          {<button className="btn-gradient px-6 py-1 border border-[#eeeeee9a] rounded-full">
          PhD Programme Notification
  </button>}
        </Link>
        <Link to="https://alstonia.bio/" target="_blank">
          {<button className="btn-gradient-alstonia px-6 py-1 border border-[#eeeeee9a] rounded-full" style={{marginLeft: 25, width:250}}>
          Alstonia Bio
  </button>}
        </Link>
        </div>
        <div className="banner-content-right bannerImgDna py-2 pl-5 sm:pl-8 md:pl-0 lg:pl-0 text-white w-full md:w-full lg:w-5/12">
          <img
            className="max-w-full bannerImg m-auto"
            src={bannerImg}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Banner;
