import React from "react";
import IsoCertificateImg from "../assets/images/SciGenomLabsPvtLtdCertificate17.jpg";
import CloseIcon from "@mui/icons-material/Close";
import { motion } from "framer-motion";

const IsoCertificate = ({ setShowIso }) => {
  return (
    <motion.div
      initial={{ height: 0 }}
      animate={{ height: "100vh" }}
      className=" fixed top-0 left-0 w-full h-screen z-50 bg-[rgba(0,0,0,0.8)] flex justify-center items-center"
    >
      <div className=" w-10/12 md:w-5/12 h-[50vh] md:h-[80vh] flex justify-center relative">
        <img className=" max-w-full" src={IsoCertificateImg} alt="" />
        <button
          onClick={() => setShowIso((prev) => !prev)}
          className=" absolute top-0 right-0 bg-transparent text-white"
        >
          <CloseIcon />
        </button>
      </div>
    </motion.div>
  );
};

export default IsoCertificate;
