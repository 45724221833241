import React from "react";

const ResearchGrantsSection = () => {
  return (
    <div className="collaboration text-slate-300 text-xs sm:text-base md:text-base lg:text-lg max-h-[70vh] overflow-y-auto">
      <h1 className="text-lg sm:text-xl md:text-2xl lg:text-3xl mb-5 bg-[#ffffff1a] px-1">
        SBIRI Grant
      </h1>
      <p className="px-1">
        One of our current research effort at SciGenom is Development of single
        tube multigene onco-diagnostic tests for use with next generation
        sequencing platforms-A project funded by Small Business Innovation
        Research Initiative(SBIRI) under Biotechnology Industry Research
        Assistance Council (BIRAC).
      </p>
      <h1 className="text-lg sm:text-xl md:text-2xl lg:text-3xl mb-5 mt-5 bg-[#ffffff1a] px-1">
        BIPP Grant
      </h1>
      <p className="px-1">
        SciGenom has been granted a fund by Department of Biotechnology (DBT),
        India under the Biotechnology Industry Partnership Program (BIPP) to
        establish a high performance computing facility which will provide
        state-of-art computing and storage resources required for the
        next-generation sequencing data analysis. The high-performance computing
        (HPC) and storage infrastructure will provide efficient and fast
        analysis facility for next-generation sequencing data from academic
        institutions and research labs. The data analysis services will be
        provided at a subsidized rate to government and academic institutions.
        The infrastructure will also host our in-house developed bioinformatics
        tools and databases. The facility will help SciGenom to collaborate with
        research institutions on projects of national importance.
      </p>
    </div>
  );
};

export default ResearchGrantsSection;
