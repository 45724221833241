import React from "react";
import "./banner3.css";
import researchicon from "../../assets/icons/Research_icon_V1.png";
import medicalPlant from "../../assets/icons/]Medicinal-Plants_icon_V1.png";
import conferance from "../../assets/icons/Conferences_icon_V1.png";
import education from "../../assets/icons/Education_icon_V1.png";
import grant from "../../assets/icons/Grant-Plants_icon_V1.png";
import communityHealth from "../../assets/icons/Community-Health_icon_V1.png";

const Banner3 = () => {
  return (
    <div
      className="banner gradient-bg3 min-h-screen h-full px-4 md:px-7 lg:px-32 pt-24 relative"
      id="sgrf"
    >
      <div className="bannerInner text-white w-full md:w-6/12">
        <div className="w-full md:w-7/12">
          <h1 className=" text-2xl sm:text-3xl lg:text-4xl mb-6 md:mb-7 lg:mb-10 lg:leading-[3.7rem] text-white">
            SciGenom Research Foundation (SGRF)
          </h1>
          <p className="bannerInnerText">
            A non-profit Trust that works to promote Science in India through
            research and education
          </p>
        </div>
      </div>
      <h3 className=" mt-5 font-semibold text-white">Activities</h3>
      <div className="activites text-white flex justify-center lg:justify-between mt-10 flex-wrap">
        <div className="activity-content w-6/12 md:w-2/12 bg-transparent flex flex-col justify-center items-center mb-4 md:mb-0">
          <a
            href="http://www.sgrf.org/research.php"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="img researchIcon spriteIcon"></div>
          </a>
          <div className="title text-lg">Research</div>
        </div>
        <div className="activity-content w-6/12 md:w-2/12 bg-transparent flex flex-col justify-center items-center mb-4 md:mb-0">
          <a
            href="http://www.sgrf.org/medicinal_plants.php"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="img medicinalPlants spriteIcon"></div>
          </a>
          <div className="title text-lg">Medicinal Plants</div>
        </div>
        <div className="activity-content w-6/12 md:w-2/12 bg-transparent flex flex-col justify-center items-center mb-4 md:mb-0">
          <a
            href="http://www.sgrf.org/conference.php"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="img conferance spriteIcon"></div>
          </a>
          <div className="title text-lg">Conferences</div>
        </div>
        <div className="activity-content w-6/12 md:w-2/12 bg-transparent flex flex-col justify-center items-center mb-4 md:mb-0">
          <a
            href="http://www.sgrf.org/education.php"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="img education spriteIcon"></div>
          </a>
          <div className="title text-lg">Education</div>
        </div>
        <div className="activity-content w-6/12 md:w-2/12 bg-transparent flex flex-col justify-center items-center mb-4 md:mb-0">
          <a
            href="http://www.sgrf.org/grants.php"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="img grant spriteIcon"></div>
          </a>
          <div className="title text-lg">Grant</div>
        </div>
        <div className="activity-content w-6/12 md:w-2/12 bg-transparent flex flex-col justify-center items-center mb-4 md:mb-0">
          <a
            href="http://www.sgrf.org/community.php"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="img communityHealth spriteIcon"></div>
          </a>
          <div className="title text-lg">Community health</div>
        </div>
      </div>
    </div>
  );
};

export default Banner3;
